import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-header" }

import {HomeIcon} from '@heroicons/vue/24/outline'

export default /*@__PURE__*/_defineComponent({
  __name: 'PageHeader',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/" }, {
      default: _withCtx(() => [
        _createVNode(_unref(HomeIcon), {
          "aria-hidden": "true",
          class: "h-8 w-8 homeicon"
        })
      ]),
      _: 1
    })
  ]))
}
}

})