<script setup lang="ts">
import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import {onMounted} from "vue";

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));
</script>

<template>
  <PageLayout>
    <h1>Ochrana osobních údajů</h1>
    <div class="text-content">
      <p>
        Dovolte, abychom vás informovali o předmětném zpracování osobních údajů, které bude naše společnost ČEZ
        Distribuce, a. s., se sídlem Děčín, Děčín IV-Podmokly, Teplická 874/8, PSČ 405 02, IČO 24729035 (dále jen „ČEZ
        Distribuce“), provádět z pozice správce osobních údajů, a o kterém jste byli informováni mj. i prostřednictvím
        školského zařízení.
      </p>
      <p>
        Naše společnost dbá na důslednou ochranu osobních údajů, zvláště pak u dětí mladších 18 let a dané řešení
        koncipovala se zaměřením na zajištění zvláštní ochrany těchto osob v souladu s relevantními ustanoveními GDPR.
      </p>
      <p>
        V souvislosti s využíváním webové aplikace budeme zpracovávat tyto osobní údaje:
      </p>
      <ul>
        <li>nutné pro registraci uživatele (žáka střední školy) v aplikaci (v rozsahu jméno a příjmení, emailová adresa,
          škola, ročník studia
        </li>
        <li>
          údaje získané při následném používání webové aplikace, konkrétně:
          <ul>
            <li>profilové (fotografie, obrázek, přezdívka), pakliže jsou vámi poskytnuty</li>
            <li>o dosažených výsledcích soutěže (bodové ohodnocení odpovídající správným otázkám při současném
              zohlednění
              rychlosti jednotlivých odpovědí vždy s dopředu uvedeným maximálním možným počtem dosažitelných bodů za
              danou
              otázku, dále celková pořadí v rámci vybrané školy a rovněž přehled pořadí účastníků celé České republiky).
            </li>
          </ul>
        </li>
      </ul>
      <p>
        Vybraná zpracování osobních údajů jsou podmíněna právním titulem souhlasu podle čl. 6 odst. 1 písm. a) GDPR
        (souhlas se zapojením do soutěže a s případným dalším kontaktováním s nabídkami pracovních příležitostí,
        případně
        vhodných odborných praxí a stáží ). Jinak využíváme právní tituly plnění nezbytné pro splnění smlouvy podle čl.
        6
        odst. 1 písm. b) GDPR a popř. oprávněný zájem podle čl. 6 odst. 1 písm. f) GDPR.
      </p>
      <p>
        Osobní údaje jsme získali přímým kontaktem od vás jakožto účastníka soutěže, další jsou získány a odvozeny na
        základě vašeho využívání webové aplikace. Vaše osobní údaje zpracováváme převážně automaticky, přičemž nedochází
        k
        automatizovanému rozhodování, včetně profilování podle čl. 22 GDPR.
      </p>
      <p>
        Osobní údaje budou uchovávány po dobu daného ročníku a následně po dobu 2 let.
      </p>
      <p>
        Pro úplnost poskytnuté informace uvádíme, že veškeré bližší informace o zpracování osobních údajů ve společnosti
        ČEZ Distribuce, naleznete na
        <a href="https://www.cezdistribuce.cz/gdpr" target="_blank">www.cezdistribuce.cz/gdpr</a>, nebo Vám je naše
        společnost na vyžádání poskytne. Na uvedeném odkaze jsou mj. skutečností uvedena i příslušná práva, kterých lze
        využít, způsobu jejich uplatnění včetně kontaktu na pověřence pro ochranu osobních údajů.
      </p>
    </div>
  </PageLayout>
</template>
