import {createApp} from 'vue'
import App from './App.vue'
import router from "./router";
import './index.css';
import {createPinia} from "pinia";
import {auth0} from "@/services/support/auth0";
import interceptor from "@/services/support/interceptor";
import bugsnag from "@/services/support/bugsnag";
import {getEnv} from "@/services/support/runtime";
import VueGtag from 'vue-gtag';

const bugsnagVue = bugsnag();

interceptor();

const app = createApp(App);

if (getEnv('GA_ENABLED') == 'true') {
    const gaKey = getEnv('GA_KEY');
    console.debug("Google Analytics enabled - key: " + gaKey);
    app.use(VueGtag, {
        config: {id: gaKey},
    }, router);
} else {
    console.warn("Google Analytics disabled");
}

if (bugsnagVue != undefined) {
    app.use(bugsnagVue);
}

app.use(router);
app.use(auth0);
app.use(createPinia());
app.mount('#app');
