<script setup lang="ts">
import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import {onMounted} from "vue";
import {i18n} from "@/services/support/i18n";
import {ArrowDownTrayIcon} from "@heroicons/vue/24/outline";

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));
</script>

<template>
  <PageLayout>
    <h1>Pravidla a podmínky</h1>

    <div class="profile-unit grid grid-cols-1 gap-y-2">
      <a class="link-box mt-2" href="/pdf/podminky-uzivani.pdf">
        <ArrowDownTrayIcon class="h-5 w-5 text-cez-color"/>
        <span>{{ i18n.termsAndConditions }}</span>
      </a>
    </div>
  </PageLayout>
</template>


<style scoped>
.link-box {
  @apply bg-gray-900 p-2 mb-1 rounded-md border-cez-color border flex items-center gap-x-2 text-sm;
}
</style>
