<script setup lang="ts">
//import {InformationCircleIcon} from "@heroicons/vue/24/outline";
import {ChatBubbleLeftIcon} from "@heroicons/vue/24/outline";
import {ref} from "vue";

const visible = ref(false);

function toggle(): void {
  if (visible.value) {
    hide();
  } else {
    show();
  }
}

function hide(): void {
  visible.value = false;
}

function show(): void {
  visible.value = true;
  setTimeout(() => {
    hide();
  }, 5000);
}
</script>

<template>
  <div class="relative">
    <div class="h-6 w-5 text-cez-color cursor-pointer" @click="toggle()">?</div>
    <transition enter-active-class="transition ease-in duration-100"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition ease-out duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
      <div class="bg-gray-900 rounded-lg absolute z-10 rounded-tl-none left-2 top-2 px-3 py-1 text-xs font-medium leading-5 text-gray-400 border border-gray-800"
           v-show="visible"
           @click="hide()">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<style scoped>

</style>