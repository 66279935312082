import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import NotificationPopup from "@/components/NotificationPopup.vue";
import {useExoUserStore} from "@/stores/exouser";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import LoadingError from "@/components/LoadingError.vue";
import {i18n} from "@/services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'MySuperApp',
  setup(__props) {


const exoUserStore = useExoUserStore();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(LoadingIndicator, {
      show: _unref(exoUserStore).loadingState == _unref(LoadingState).LOADING
    }, null, 8, ["show"]),
    (_unref(exoUserStore).loadingState == _unref(LoadingState).ERROR)
      ? (_openBlock(), _createBlock(LoadingError, {
          key: 0,
          "show-text": true,
          text: _unref(i18n).exoUserLoadingFailed,
          class: "mt-5"
        }, null, 8, ["text"]))
      : _createCommentVNode("", true),
    _createVNode(NotificationPopup)
  ], 64))
}
}

})