import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "relative mt-0.5 rounded-md shadow-sm" }
const _hoisted_3 = ["type", "name", "disabled", "placeholder", "value"]
const _hoisted_4 = {
  key: 0,
  class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
}
const _hoisted_5 = {
  key: 0,
  class: "mt-2 text-sm text-red-600"
}

import {ExclamationCircleIcon} from '@heroicons/vue/20/solid'
import {ref} from "vue";
import MyToolTip from "@/components/MyToolTip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyInput',
  props: {
    modelValue: {},
    name: {},
    label: {},
    type: { default: 'text' },
    disabled: { type: Boolean, default: false },
    placeholder: {},
    toolTip: {}
  },
  emits: ["update:modelValue"],
  setup(__props: any, { expose: __expose, emit: __emit }) {



const emit = __emit;

const valid = ref(true);
const message = ref('');

const updateModelValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value)
};

function setValid(_valid: boolean, _message: string = '') {
  valid.value = _valid;
  message.value = _message
}

__expose({
  setValid
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", {
      for: _ctx.name,
      class: "flex gap-2 text-xs font-medium leading-6 text-gray-400 items-center"
    }, [
      _createElementVNode("span", null, _toDisplayString(_ctx.label), 1),
      (_ctx.toolTip)
        ? (_openBlock(), _createBlock(MyToolTip, { key: 0 }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.toolTip), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ], 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", {
        type: _ctx.type,
        name: _ctx.name,
        disabled: _ctx.disabled,
        placeholder: _ctx.placeholder,
        class: _normalizeClass(["block w-full rounded-md border-0 py-1.5 ring-1 ring-inset ring-gray-600 focus:ring-2 focus:ring-inset disabled:text-gray-500 leading-6 bg-gray-700 text-white", valid.value ?
                    'text-gray-900 shadow-sm ring-gray-300 placeholder:text-gray-400 focus:ring-cez-color'
                    : 'pr-10 text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500']),
        value: _ctx.modelValue,
        onInput: updateModelValue
      }, null, 42, _hoisted_3),
      (!valid.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_unref(ExclamationCircleIcon), {
              class: "h-5 w-5 text-red-500",
              "aria-hidden": "true"
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (!valid.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(message.value), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})