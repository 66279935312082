import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-center text-cez-color"
}
const _hoisted_2 = {
  key: 1,
  class: "score-line"
}
const _hoisted_3 = { class: "w-14 text-right" }
const _hoisted_4 = { class: "flex items-center flex-shrink overflow-hidden" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "pl-3 pr-6 flex-shrink overflow-hidden" }
const _hoisted_7 = { class: "nickname" }
const _hoisted_8 = { class: "teams" }
const _hoisted_9 = { class: "icon-box flex-shrink-0" }
const _hoisted_10 = { class: "whitespace-nowrap w-14" }

import {useExoUserStore} from "@/stores/exouser";
import {BoltIcon} from "@heroicons/vue/24/outline";
import {IScore} from "@/services/contracts/IScore";


export default /*@__PURE__*/_defineComponent({
  __name: 'ScoreLine',
  props: {
    data: {}
  },
  setup(__props: any) {


const exoUserStore = useExoUserStore();



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.data.padding)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, " ... "))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.data.rank) + ". ", 1),
          _createElementVNode("div", {
            class: _normalizeClass(["flex bg-gray-900 rounded-full items-center justify-between w-full flex-shrink overflow-hidden", _ctx.data.idExoUser == _unref(exoUserStore).exoUser.id ? 'selected' : ''])
          }, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("img", {
                class: "block h-12 w-12 rounded-full border-orange-500 border-2 flex-shrink-0 flex-grow-0",
                src: _ctx.data.imageData == null ? '/img/logo.png' : _ctx.data.imageData,
                alt: ""
              }, null, 8, _hoisted_5),
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.data.nickname), 1),
                _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.data.teams), 1)
              ])
            ])
          ], 2),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(BoltIcon), { class: "h-6 w-6 bg-orange-500 rounded-full p-1" }),
            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.data.points), 1)
          ])
        ]))
  ]))
}
}

})