import api from "@/services/support/api";
import {ICompetitionList} from "@/services/contracts/ICompetitionList";

class TeacherCompetitionService {

    find() {
        return api.get<ICompetitionList>('teacher-competitions');
    }
}

export default new TeacherCompetitionService();
