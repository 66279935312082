import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {mathjax} from "mathjax-full/js/mathjax.js";
//import {TeX} from "mathjax-full/js/input/tex.js";
//import {CHTML} from "mathjax-full/js/output/chtml.js";
import {SVG} from 'mathjax-full/js/output/svg'
import {browserAdaptor} from "mathjax-full/js/adaptors/browserAdaptor.js";
import {AsciiMath} from "mathjax-full/js/input/asciimath.js"
// Register the HTML document handler
import {RegisterHTMLHandler} from "mathjax-full/js/handlers/html.js";
import {onMounted} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyMathjax',
  setup(__props) {

RegisterHTMLHandler(browserAdaptor());

const html = mathjax.document(document, {
  InputJax: [
    new AsciiMath({
      delimiters: [['`', '`'], ['$', '$'], ['\\(', '\\)']],
    }),
    // new TeX({
    //   inlineMath: [['$', '$'], ['\\(', '\\)']],
    //   packages: ['base', 'ams', 'noundefined', 'newcommand', 'boldsymbol']
    // })
  ],
  OutputJax: new SVG({})
});

onMounted(() => {
  console.log("Mathjax mounted");
  html.findMath()
      .compile()
      .getMetrics()
      .typeset()
      .updateDocument()
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span"))
}
}

})