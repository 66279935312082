import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import {useAuth0} from "@auth0/auth0-vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LogoutPage',
  setup(__props) {

const {logout} = useAuth0();

logout({
  logoutParams: {
    returnTo: window.location.origin,
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div"))
}
}

})