import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center" }
const _hoisted_2 = { class: "flex items-center pt-5 pb-3" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "pl-3" }
const _hoisted_5 = { class: "text-sm text-gray-500" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "mb-1" }

import {useExoUserStore} from "@/stores/exouser";
import MyButton from "@/components/MyButton.vue";
import {i18n} from "@/services/support/i18n";
import {TrophyIcon} from "@heroicons/vue/24/outline";
import {useTeacherCompetitionListStore} from "@/stores/teacherCompetitionList";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeProfileBanner',
  setup(__props) {


const exoUserStore = useExoUserStore();
const teacherCompetitionListStore = useTeacherCompetitionListStore();

teacherCompetitionListStore.fetch();


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, { to: "/profil" }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "inline-block h-12 w-12 rounded-full border-orange-500 border-2",
            src: _unref(exoUserStore).exoUser.profileImageData == null ? '/img/logo.png' : _unref(exoUserStore).exoUser.profileImageData,
            alt: ""
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", null, _toDisplayString(_unref(exoUserStore).fullName), 1),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(exoUserStore).exoUser.nickname), 1)
          ])
        ]),
        (!_unref(exoUserStore).isProfileCompleted)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(i18n).profileIncomplete), 1),
              _cache[0] || (_cache[0] = _createElementVNode("p", null, null, -1)),
              _createVNode(MyButton, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(i18n).completeProfile), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    (_unref(teacherCompetitionListStore).list.items.length > 0)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: "/vysledky-pro-ucitele",
          title: "Výsledky (pouze pro učitele)"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(TrophyIcon), { class: "w-8 h-8 text-orange-500" })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})