<template>
  <!-- Global notification live region, render this permanently at the end of the document -->
  <div ref="el"
       class="pointer-events-none fixed bottom-0 right-0 inset-0 flex px-4 py-6 z-50">
    <div class="flex w-full flex-col items-center space-y-4">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition enter-active-class="transform ease-out duration-300 transition"
                  enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                  enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
                  leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <div v-if="visible"
             class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-100 shadow-lg ring-1 ring-black ring-opacity-5">
          <div class="p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">Neuložené změny</p>
                <p class="mt-1 text-sm text-gray-500">V profilu jsou neuložené změny. Zahodit změny a odejít?</p>

                <div class="mt-4 flex">
                  <MyButton type="button" @click="router.push('/')">Odejít</MyButton>
                </div>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                    class="inline-flex rounded-md bg-red-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    type="button"
                    @click="visible = false">
                  <XMarkIcon class="h-5 w-5"/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import {XMarkIcon} from '@heroicons/vue/20/solid'
import {ref} from "vue";
import MyButton from "@/components/MyButton.vue";
import {useRouter} from "vue-router";

const router = useRouter();
const visible = ref(false);
const el = ref();

function show() {
  visible.value = true;
}

defineExpose({show});

</script>