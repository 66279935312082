import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "profile-unit",
  id: "team"
}
const _hoisted_2 = { class: "flex items-center justify-between" }
const _hoisted_3 = { class: "w-36" }
const _hoisted_4 = { class: "mt-2" }

import MyInput from "@/components/MyInput.vue";
import {useTeamStore} from "@/stores/team";
import MyButton from "@/components/MyButton.vue";
import {ClockIcon, QuestionMarkCircleIcon, UsersIcon, XCircleIcon} from "@heroicons/vue/24/outline";
import {MembershipState} from "@/services/contracts/MembershipState";
import {ITeam} from "@/services/contracts/ITeam";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LoadingError from "@/components/LoadingError.vue";
import {useTeamListStore} from "@/stores/teamList";
import {onMounted, ref, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import {useRoute} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileTeams',
  setup(__props) {


const teamListStore = useTeamListStore();
const teamStore = useTeamStore();
const accessCode = ref('');
const input = ref();
const route = useRoute();

const listClass = ref("");

onMounted(() => {
  teamListStore.fetch();
  focusTeamInput(route.hash);
})

watch(() => route.hash, (newHash) => {
  focusTeamInput(newHash);
});

function focusTeamInput(hash: string) {
  if (hash && hash === "#team" && input.value) {
    document.querySelector('.team-input input')?.focus();
  }
}

async function joinTeam() {
  accessCode.value = accessCode.value.toUpperCase();

  try {
    await teamStore.joinTeam(accessCode.value);
    accessCode.value = "";
    listClass.value = "highlight";
    setTimeout(() => listClass.value = "", 1000);
  } catch {
    // leave access code in input
  }
}

function leaveTeam(team: ITeam) {
  teamStore.leaveTeam(team);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_unref(i18n).teams), 1),
    _createVNode(LoadingSpinner, {
      show: _unref(teamListStore).loadingState == _unref(LoadingState).LOADING
    }, null, 8, ["show"]),
    (_unref(teamListStore).loadingState == _unref(LoadingState).ERROR)
      ? (_openBlock(), _createBlock(LoadingError, { key: 0 }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass(listClass.value)
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(teamListStore).teamState.items, (team) => {
        return (_openBlock(), _createElementBlock("div", {
          key: team.id,
          class: "mt-1.5",
          content: false
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", null, [
              (team.state == _unref(MembershipState).PENDING)
                ? (_openBlock(), _createBlock(_unref(ClockIcon), {
                    key: 0,
                    class: "h-5 w-5 text-cez-color inline"
                  }))
                : _createCommentVNode("", true),
              (team.state == _unref(MembershipState).ACCEPTED)
                ? (_openBlock(), _createBlock(_unref(UsersIcon), {
                    key: 1,
                    class: "h-5 w-5 text-cez-color inline"
                  }))
                : _createCommentVNode("", true),
              (team.state == _unref(MembershipState).DENIED)
                ? (_openBlock(), _createBlock(_unref(XCircleIcon), {
                    key: 2,
                    class: "h-5 w-5 text-red-600 inline"
                  }))
                : _createCommentVNode("", true),
              (team.state == _unref(MembershipState).UNKNOWN)
                ? (_openBlock(), _createBlock(_unref(QuestionMarkCircleIcon), {
                    key: 3,
                    class: "h-5 w-5 text-red-600 inline"
                  }))
                : _createCommentVNode("", true),
              _createTextVNode(" " + _toDisplayString(team.teamName) + ", " + _toDisplayString(team.institutionName) + ", " + _toDisplayString(team.competitionName), 1)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(MyButton, {
                class: "leave-button",
                onClick: ($event: any) => (leaveTeam(team)),
                busy: _unref(teamStore).isLeavingBusy.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(i18n).leaveTeam), 1)
                ]),
                _: 2
              }, 1032, ["onClick", "busy"])
            ])
          ])
        ]))
      }), 128))
    ], 2),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(MyInput, {
        class: "team-input",
        ref_key: "input",
        ref: input,
        label: _unref(i18n).accessCode,
        type: "text",
        modelValue: accessCode.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((accessCode).value = $event)),
        name: "accessCode",
        "tool-tip": "Kód obdržíš od svého vyučujícího a budeš automaticky přiřazen do odpovídající kategorie. Jedná se o kombinaci číslic a písmen."
      }, null, 8, ["label", "modelValue"]),
      _createVNode(MyButton, {
        class: "mt-2",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (joinTeam())),
        disabled: accessCode.value == '',
        busy: _unref(teamStore).isJoiningBusy.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(i18n).joinTeam), 1)
        ]),
        _: 1
      }, 8, ["disabled", "busy"])
    ])
  ]))
}
}

})