import {Ref, ref} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {IInstitutionList} from "@/services/contracts/IInstitutionList";
import {defineStore} from "pinia";
import {logError} from "@/services/support/error";
import TeacherInstitutionService from "@/services/TeacherInstitutionService";

export const useTeacherInstitutionListStore
    = defineStore('teacherInstitutions', () => {

    const EMPTY: IInstitutionList = {items: []};
    const loadingState = ref(LoadingState.LOADING);
    const list: Ref<IInstitutionList> = ref(EMPTY);

    async function fetch(competitionId: number | null) {

        console.debug('Fetching teacher institutions...');
        loadingState.value = LoadingState.LOADING;

        if (competitionId == null) {
            list.value = EMPTY;
            loadingState.value = LoadingState.OK;
            return;
        }

        try {
            const response = await TeacherInstitutionService.find(competitionId);
            list.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            list.value = EMPTY;
            loadingState.value = LoadingState.ERROR;
            logError('Failed to fetch teacher institutions', err);
        }
    }

    return {list, loadingState, fetch}
});