import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "answeredQuestion border-t border-gray-700 mt-5 py-5" }
const _hoisted_2 = { class: "icon-box" }
const _hoisted_3 = { class: "flex justify-between" }
const _hoisted_4 = { class: "icon-box" }
const _hoisted_5 = { class: "whitespace-nowrap w-10" }
const _hoisted_6 = { class: "icon-box" }
const _hoisted_7 = { class: "whitespace-nowrap w-10" }
const _hoisted_8 = ["src"]
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "w-full" }

import {IAnswer} from "@/services/contracts/IAnswer";
import {BeakerIcon, BoltIcon, CheckIcon, ClockIcon, XMarkIcon} from "@heroicons/vue/24/outline";
import {computed} from "vue";
import {millisecondsToString} from "@/services/support/timeformat";


export default /*@__PURE__*/_defineComponent({
  __name: 'AnsweredQuestionDetail',
  props: {
    answer: {},
    answerCount: {},
    answerPosition: {}
  },
  setup(__props: any) {

const props = __props

const elapsedTimeAsString = computed(() => {
  if (props.answer.elapsedTime != null) {
    return millisecondsToString(props.answer.elapsedTime)
  }
  return millisecondsToString(props.answer.maxResponseTime);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(BeakerIcon), {
        "aria-hidden": "true",
        class: "h-7 w-7 text-orange-500"
      }),
      _createTextVNode(" " + _toDisplayString(_ctx.answerPosition + 1) + " / " + _toDisplayString(_ctx.answerCount), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_unref(BoltIcon), {
          "aria-hidden": "true",
          class: "h-6 w-6 bg-orange-500 rounded-full p-1"
        }),
        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.answer.points) + " / " + _toDisplayString(_ctx.answer.maxPoints) + " b.", 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(ClockIcon), {
          "aria-hidden": "true",
          class: "h-7 w-7 text-orange-500"
        }),
        _createElementVNode("span", _hoisted_7, _toDisplayString(elapsedTimeAsString.value), 1)
      ])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answer.embeddedImages, (image) => {
      return (_openBlock(), _createElementBlock("div", {
        key: image,
        class: "py-1"
      }, [
        _createElementVNode("img", {
          src: image,
          class: "max-w-full"
        }, null, 8, _hoisted_8)
      ]))
    }), 128)),
    _createElementVNode("p", null, _toDisplayString(_ctx.answer.questionText), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.answer.questionChoices, (choice) => {
      return (_openBlock(), _createElementBlock("div", {
        key: choice.id,
        class: _normalizeClass(["block my-3 radio", choice.id == _ctx.answer.selectedChoiceId ? (_ctx.answer.correct ? 'correct ': '') + 'selected' : ''])
      }, [
        _createElementVNode("label", {
          for: 'choice-'+choice.id,
          class: "flex p-3 w-full bg-gray-600 rounded-md justify-between"
        }, [
          _cache[0] || (_cache[0] = _createElementVNode("span", { class: "border border-white rounded-full mr-2 w-5 h-5 mt-0.5 flex items-center justify-center shrink-0" }, [
            _createElementVNode("span", { class: "block bg-white rounded-full w-3 h-3" })
          ], -1)),
          _createElementVNode("span", _hoisted_10, _toDisplayString(choice.text), 1),
          (choice.id == _ctx.answer.selectedChoiceId && _ctx.answer.correct)
            ? (_openBlock(), _createBlock(_unref(CheckIcon), {
                key: 0,
                class: "h-6 w-6"
              }))
            : _createCommentVNode("", true),
          (choice.id == _ctx.answer.selectedChoiceId && !_ctx.answer.correct)
            ? (_openBlock(), _createBlock(_unref(XMarkIcon), {
                key: 1,
                class: "h-6 w-6"
              }))
            : _createCommentVNode("", true)
        ], 8, _hoisted_9)
      ], 2))
    }), 128))
  ]))
}
}

})