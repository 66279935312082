import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "relative mt-0.5" }
const _hoisted_2 = { class: "block truncate" }
const _hoisted_3 = { class: "pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2" }
const _hoisted_4 = { class: "block truncate" }

import {CheckIcon, ChevronUpDownIcon} from "@heroicons/vue/20/solid";
import {Listbox, ListboxButton, ListboxOption, ListboxOptions} from "@headlessui/vue";
import {useInstitutionListStore} from "@/stores/institutionList";
import {watch} from "vue";
import {useScoreStore} from "@/stores/score";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstitutionCombo',
  setup(__props) {

const institutionStore = useInstitutionListStore();
const scoreStore = useScoreStore();

watch(() => scoreStore.competition, async (val) => {
  await institutionStore.fetch(val == null ? null : val.id);
  scoreStore.institution = institutionStore.list.items[0];
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Listbox), {
    as: "div",
    modelValue: _unref(scoreStore).institution,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(scoreStore).institution) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_unref(ListboxButton), { class: "relative w-full cursor-default rounded-md bg-gray-700 text-white py-1.5 pl-3 pr-10 text-left shadow-sm ring-1 ring-inset ring-gray-600 focus:outline-none focus:ring-2 focus:ring-cez-color leading-6" }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(scoreStore).institution == null ? " " : _unref(scoreStore).institution.institutionName), 1),
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_unref(ChevronUpDownIcon), {
                class: "h-5 w-5 text-gray-400",
                "aria-hidden": "true"
              })
            ])
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(ListboxOptions), { class: "absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-gray-600 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(institutionStore).list.items, (item) => {
                  return (_openBlock(), _createBlock(_unref(ListboxOption), {
                    as: "template",
                    key: item.id,
                    value: item
                  }, {
                    default: _withCtx(({ active, selected }) => [
                      _createElementVNode("li", {
                        class: _normalizeClass(["relative cursor-default select-none py-2 pl-8 pr-4", active ? 'bg-cez-color text-white' : 'text-gray-white'])
                      }, [
                        _createElementVNode("span", _hoisted_4, _toDisplayString(item.institutionName), 1),
                        selected
                          ? (_openBlock(), _createElementBlock("span", {
                              key: 0,
                              class: _normalizeClass([active ? 'text-white' : 'text-cez-color', "absolute inset-y-0 left-0 flex items-center pl-1.5"])
                            }, [
                              _createVNode(_unref(CheckIcon), {
                                class: "h-5 w-5",
                                "aria-hidden": "true"
                              })
                            ], 2))
                          : _createCommentVNode("", true)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})