import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import {onMounted} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PrivacyPolicyPage',
  setup(__props) {

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageLayout, null, {
    default: _withCtx(() => _cache[0] || (_cache[0] = [
      _createElementVNode("h1", null, "Ochrana osobních údajů", -1),
      _createElementVNode("div", { class: "text-content" }, [
        _createElementVNode("p", null, " Dovolte, abychom vás informovali o předmětném zpracování osobních údajů, které bude naše společnost ČEZ Distribuce, a. s., se sídlem Děčín, Děčín IV-Podmokly, Teplická 874/8, PSČ 405 02, IČO 24729035 (dále jen „ČEZ Distribuce“), provádět z pozice správce osobních údajů, a o kterém jste byli informováni mj. i prostřednictvím školského zařízení. "),
        _createElementVNode("p", null, " Naše společnost dbá na důslednou ochranu osobních údajů, zvláště pak u dětí mladších 18 let a dané řešení koncipovala se zaměřením na zajištění zvláštní ochrany těchto osob v souladu s relevantními ustanoveními GDPR. "),
        _createElementVNode("p", null, " V souvislosti s využíváním webové aplikace budeme zpracovávat tyto osobní údaje: "),
        _createElementVNode("ul", null, [
          _createElementVNode("li", null, "nutné pro registraci uživatele (žáka střední školy) v aplikaci (v rozsahu jméno a příjmení, emailová adresa, škola, ročník studia "),
          _createElementVNode("li", null, [
            _createTextVNode(" údaje získané při následném používání webové aplikace, konkrétně: "),
            _createElementVNode("ul", null, [
              _createElementVNode("li", null, "profilové (fotografie, obrázek, přezdívka), pakliže jsou vámi poskytnuty"),
              _createElementVNode("li", null, "o dosažených výsledcích soutěže (bodové ohodnocení odpovídající správným otázkám při současném zohlednění rychlosti jednotlivých odpovědí vždy s dopředu uvedeným maximálním možným počtem dosažitelných bodů za danou otázku, dále celková pořadí v rámci vybrané školy a rovněž přehled pořadí účastníků celé České republiky). ")
            ])
          ])
        ]),
        _createElementVNode("p", null, " Vybraná zpracování osobních údajů jsou podmíněna právním titulem souhlasu podle čl. 6 odst. 1 písm. a) GDPR (souhlas se zapojením do soutěže a s případným dalším kontaktováním s nabídkami pracovních příležitostí, případně vhodných odborných praxí a stáží ). Jinak využíváme právní tituly plnění nezbytné pro splnění smlouvy podle čl. 6 odst. 1 písm. b) GDPR a popř. oprávněný zájem podle čl. 6 odst. 1 písm. f) GDPR. "),
        _createElementVNode("p", null, " Osobní údaje jsme získali přímým kontaktem od vás jakožto účastníka soutěže, další jsou získány a odvozeny na základě vašeho využívání webové aplikace. Vaše osobní údaje zpracováváme převážně automaticky, přičemž nedochází k automatizovanému rozhodování, včetně profilování podle čl. 22 GDPR. "),
        _createElementVNode("p", null, " Osobní údaje budou uchovávány po dobu daného ročníku a následně po dobu 2 let. "),
        _createElementVNode("p", null, [
          _createTextVNode(" Pro úplnost poskytnuté informace uvádíme, že veškeré bližší informace o zpracování osobních údajů ve společnosti ČEZ Distribuce, naleznete na "),
          _createElementVNode("a", {
            href: "https://www.cezdistribuce.cz/gdpr",
            target: "_blank"
          }, "www.cezdistribuce.cz/gdpr"),
          _createTextVNode(", nebo Vám je naše společnost na vyžádání poskytne. Na uvedeném odkaze jsou mj. skutečností uvedena i příslušná práva, kterých lze využít, způsobu jejich uplatnění včetně kontaktu na pověřence pro ochranu osobních údajů. ")
        ])
      ], -1)
    ])),
    _: 1
  }))
}
}

})