import {Ref, ref} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {IScoreList} from "@/services/contracts/IScoreList";
import {defineStore} from "pinia";
import {ICompetition} from "@/services/contracts/ICompetition";
import {IInstitution} from "@/services/contracts/IInstitution";
import {logError} from "@/services/support/error";
import TeacherScoreService from "@/services/TeacherScoreService";
import {useTeacherCompetitionListStore} from "@/stores/teacherCompetitionList";

export const useTeacherScoreStore
    = defineStore('teacherScore', () => {

    const EMPTY: IScoreList = {items: []};
    const loadingState = ref(LoadingState.LOADING);
    const list: Ref<IScoreList> = ref(EMPTY);

    const competition: Ref<ICompetition | null> = ref(null);
    const institution: Ref<IInstitution | null> = ref(null);

    const competitionListStore = useTeacherCompetitionListStore();

    async function onMounted() {
        await competitionListStore.fetch();

        if (competition.value == null) {
            const competitions = competitionListStore.list.items;
            competition.value = competitions.length > 0 ? competitions[0] : null;
        } else {
            await fetch();
        }
    }

    async function fetch() {

        console.debug('Fetching teacher score...');
        loadingState.value = LoadingState.LOADING;

        if (competition.value == null) {
            console.warn('No teacher competition available!');
            list.value = EMPTY;
            loadingState.value = LoadingState.OK;
            return;
        }

        const idCompetition = competition.value.id;
        const idInstitution = institution.value != null ? institution.value.id : 0;

        try {
            const scoreResult = await TeacherScoreService.find(idCompetition, idInstitution);
            list.value = scoreResult.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            loadingState.value = LoadingState.ERROR;
            logError(`Failed to fetch teacher score - competition: ${idCompetition}, institution: ${idInstitution}`, err);
        }
    }

    return {list, competition, institution, loadingState, onMounted, fetch}
})