<script setup lang="ts">

import {useExoUserStore} from "@/stores/exouser";
import MyButton from "@/components/MyButton.vue";
import {i18n} from "@/services/support/i18n";
import {TrophyIcon} from "@heroicons/vue/24/outline";
import {useTeacherCompetitionListStore} from "@/stores/teacherCompetitionList";

const exoUserStore = useExoUserStore();
const teacherCompetitionListStore = useTeacherCompetitionListStore();

teacherCompetitionListStore.fetch();

</script>

<template>
  <div class="flex justify-between items-center">
    <router-link to="/profil">
      <div class="flex items-center pt-5 pb-3">
        <img class="inline-block h-12 w-12 rounded-full border-orange-500 border-2"
             :src="exoUserStore.exoUser.profileImageData == null ? '/img/logo.png' : exoUserStore.exoUser.profileImageData"
             alt=""/>
        <div class="pl-3">
          <p>{{ exoUserStore.fullName }}</p>
          <p class="text-sm text-gray-500">{{ exoUserStore.exoUser.nickname }}</p>
        </div>
      </div>
      <div v-if="!exoUserStore.isProfileCompleted">
        <div class="mb-1">{{ i18n.profileIncomplete }}</div>
        <p></p>
        <MyButton>{{ i18n.completeProfile }}</MyButton>
      </div>
    </router-link>

    <router-link to="/vysledky-pro-ucitele" v-if="teacherCompetitionListStore.list.items.length > 0"
                 title="Výsledky (pouze pro učitele)">
      <TrophyIcon class="w-8 h-8 text-orange-500"/>
    </router-link>
  </div>
</template>

<style scoped>

</style>