<template>
  <router-view/>
  <MySuperApp v-if="!isLoading && isAuthenticated"/>
</template>

<style>
</style>

<script setup lang="ts">
import {useAuth0} from "@auth0/auth0-vue";
import MySuperApp from "@/components/MySuperApp.vue";

const {isLoading, isAuthenticated} = useAuth0();
</script>