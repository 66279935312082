import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "mb-1" }

import {useTeamListStore} from "@/stores/teamList";
import {LoadingState} from "@/services/contracts/LoadingState";
import {computed, onMounted} from "vue";
import {MembershipState} from "@/services/contracts/MembershipState";
import MyButton from "@/components/MyButton.vue";
import LoadingError from "@/components/LoadingError.vue";
import {i18n} from "@/services/support/i18n";
import HomeScoreBoard from "@/components/ScoreBoard.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeTeamList',
  setup(__props) {

const teamsListStore = useTeamListStore();
const loadingState = computed(() => teamsListStore.loadingState);
const teams = computed(() => teamsListStore.teamState.items.filter(t => t.state == MembershipState.ACCEPTED));

onMounted(() => {
  teamsListStore.fetch();
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_unref(i18n).team), 1),
    _createVNode(LoadingIndicator, {
      show: loadingState.value == _unref(LoadingState).LOADING && teams.value.length == 0
    }, null, 8, ["show"]),
    (loadingState.value == _unref(LoadingState).ERROR)
      ? (_openBlock(), _createBlock(LoadingError, { key: 0 }))
      : _createCommentVNode("", true),
    (loadingState.value != _unref(LoadingState).ERROR)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (teams.value.length < 1 && loadingState.value == _unref(LoadingState).OK)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(i18n).notATeamMember), 1),
                _createVNode(_component_router_link, { to: "/profil#team" }, {
                  default: _withCtx(() => [
                    _createVNode(MyButton, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_unref(i18n).joinTeam), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]))
            : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(teams.value, (team) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: team.id,
                  class: "mt-1"
                }, [
                  _createElementVNode("div", null, _toDisplayString(team.teamName) + ", " + _toDisplayString(team.institutionName) + ", " + _toDisplayString(team.competitionName), 1),
                  _createVNode(HomeScoreBoard, { team: team }, null, 8, ["team"])
                ]))
              }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})