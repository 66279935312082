import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col items-center space-y-4" }
const _hoisted_2 = {
  key: 0,
  class: "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-100 shadow-lg ring-1 ring-black ring-opacity-5"
}
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "flex items-start" }
const _hoisted_5 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_6 = { class: "mt-4 flex" }
const _hoisted_7 = { class: "ml-4 flex flex-shrink-0" }

import {XMarkIcon} from '@heroicons/vue/20/solid'
import {ref} from "vue";
import MyButton from "@/components/MyButton.vue";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeNotificationPopup',
  setup(__props, { expose: __expose }) {

const router = useRouter();
const visible = ref(false);
const el = ref();

function show() {
  visible.value = true;
}

__expose({show});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "el",
    ref: el,
    class: "pointer-events-none fixed bottom-0 right-0 inset-0 flex px-4 py-6 z-50"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_Transition, {
        "enter-active-class": "transform ease-out duration-300 transition",
        "enter-from-class": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
        "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
        "leave-active-class": "transition ease-in duration-100",
        "leave-from-class": "opacity-100",
        "leave-to-class": "opacity-0"
      }, {
        default: _withCtx(() => [
          (visible.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _cache[3] || (_cache[3] = _createElementVNode("p", { class: "text-sm font-medium text-gray-900" }, "Neuložené změny", -1)),
                      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "mt-1 text-sm text-gray-500" }, "V profilu jsou neuložené změny. Zahodit změny a odejít?", -1)),
                      _createElementVNode("div", _hoisted_6, [
                        _createVNode(MyButton, {
                          type: "button",
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/')))
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createTextVNode("Odejít")
                          ])),
                          _: 1
                        })
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("button", {
                        class: "inline-flex rounded-md bg-red-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                        type: "button",
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (visible.value = false))
                      }, [
                        _createVNode(_unref(XMarkIcon), { class: "h-5 w-5" })
                      ])
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ])
  ], 512))
}
}

})