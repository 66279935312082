<script setup lang="ts">
import {AcademicCapIcon, BoltIcon, CursorArrowRippleIcon, DocumentCheckIcon} from '@heroicons/vue/24/outline'
import {i18n} from "@/services/support/i18n";
import {ITeam} from "@/services/contracts/ITeam";

defineProps<{ team: ITeam }>();

function answerPercent(t: ITeam) {
  if (t.userScore) {
    if (t.userScore.totalAnswers > 0) {
      return (100 * t.userScore?.correctAnswers / t.userScore?.totalAnswers).toFixed(0) + '%';
    }
    return '0%';
  }
  return "?";
}
</script>

<template>
  <router-link to="/vysledky">
    <div class="score-box-wrapper">
      <div class="score-box">
        <div class="box-headline">{{ i18n.scorePoints }}</div>
        <div class="content">
          <BoltIcon class="icon"/>
          <div>
            <span class="value">{{ team.userScore?.points }}</span>
            <span class="total">/{{ team.userScore?.totalPoints }}</span>
          </div>
        </div>
      </div>
      <div class="score-box">
        <div class="box-headline">{{ i18n.completedQuizzes }}</div>
        <div class="content">
          <DocumentCheckIcon class="icon"/>
          <div>
            <span class="value">{{ team.userScore?.finishedQuizzes }}</span>
            <span class="total">/{{ team.userScore?.totalQuizzes }}</span>
          </div>
        </div>
      </div>
      <div class="score-box">
        <div class="box-headline">{{ i18n.correctAnswers }}</div>
        <div class="content">
          <CursorArrowRippleIcon class="icon"/>
          <span class="value">{{ answerPercent(team) }}</span>
        </div>
      </div>
      <div class="score-box">
        <div class="box-headline">{{ i18n.competitionRank }}</div>
        <div class="content flex">
          <div>
            <AcademicCapIcon class="icon"/>
          </div>
          <div>
            <div>
              <span class="value">{{ team.userScore?.totalRank == 0 ? "-" : (team.userScore?.totalRank + '.') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<style scoped>
.score-box-wrapper {
  @apply gap-1 flex-wrap my-3 grid grid-cols-2 sm:flex;
}

.score-box {
  @apply bg-gray-900 p-2 mb-1 rounded-md border-orange-500 border grow basis-1;
}

.score-box .box-headline {
}

.score-box .content {
  @apply flex pt-1 items-center;
  justify-content: space-between;
}

.score-box .icon {
  @apply w-6 h-6;
}

.score-box .content .value {
  @apply font-bold;
}

.score-box .content .total {
  @apply text-gray-500;
}

.score-box .content svg {
  @apply text-orange-500;
}
</style>