import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import HomeProfileBanner from "@/components/HomeProfileBanner.vue";
import HomeQuizList from "@/components/HomeQuizList.vue";
import {useVersionStore} from "@/stores/version";
import HomeTeamList from "@/components/HomeTeamList.vue";
import {onMounted} from "vue";
import {useExoUserStore} from "@/stores/exouser";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const versionStore = useVersionStore();
const exoUserStore = useExoUserStore();
onMounted(() => versionStore.check(true));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HomeProfileBanner),
    (_unref(exoUserStore).isProfileCompleted)
      ? (_openBlock(), _createBlock(HomeTeamList, { key: 0 }))
      : _createCommentVNode("", true),
    (_unref(exoUserStore).isProfileCompleted)
      ? (_openBlock(), _createBlock(HomeQuizList, { key: 1 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})