import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "text-center relative" }

import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {ref, watch} from "vue";

interface Props {
  busy?: boolean,
  disabled?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'MyButton',
  props: {
    busy: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {


const props = __props;

const busy = ref(props.busy);
const disabled = ref(props.disabled);
const pushed = ref(false);

watch(() => props.busy, () => {
  busy.value = props.busy;
});

watch(() => props.disabled, () => {
  disabled.value = props.disabled;
})

const emit = __emit;

function click() {
  pushed.value = true;
  setTimeout(() => {
    pushed.value = false;
  }, 250);

  emit('click');
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("button", {
      type: "button",
      onClick: click,
      disabled: disabled.value || pushed.value || busy.value
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default"),
        _createVNode(LoadingSpinner, {
          show: busy.value,
          class: "text-cez-color absolute right-1 bottom-1/4"
        }, null, 8, ["show"])
      ])
    ], 8, _hoisted_1)
  ]))
}
}

})