import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "score-box-wrapper" }
const _hoisted_2 = { class: "score-box" }
const _hoisted_3 = { class: "box-headline" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "value" }
const _hoisted_6 = { class: "total" }
const _hoisted_7 = { class: "score-box" }
const _hoisted_8 = { class: "box-headline" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "value" }
const _hoisted_11 = { class: "total" }
const _hoisted_12 = { class: "score-box" }
const _hoisted_13 = { class: "box-headline" }
const _hoisted_14 = { class: "content" }
const _hoisted_15 = { class: "value" }
const _hoisted_16 = { class: "score-box" }
const _hoisted_17 = { class: "box-headline" }
const _hoisted_18 = { class: "content flex" }
const _hoisted_19 = { class: "value" }

import {AcademicCapIcon, BoltIcon, CursorArrowRippleIcon, DocumentCheckIcon} from '@heroicons/vue/24/outline'
import {i18n} from "@/services/support/i18n";
import {ITeam} from "@/services/contracts/ITeam";


export default /*@__PURE__*/_defineComponent({
  __name: 'ScoreBoard',
  props: {
    team: {}
  },
  setup(__props: any) {



function answerPercent(t: ITeam) {
  if (t.userScore) {
    if (t.userScore.totalAnswers > 0) {
      return (100 * t.userScore?.correctAnswers / t.userScore?.totalAnswers).toFixed(0) + '%';
    }
    return '0%';
  }
  return "?";
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, { to: "/vysledky" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(i18n).scorePoints), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_unref(BoltIcon), { class: "icon" }),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.team.userScore?.points), 1),
              _createElementVNode("span", _hoisted_6, "/" + _toDisplayString(_ctx.team.userScore?.totalPoints), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(i18n).completedQuizzes), 1),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(DocumentCheckIcon), { class: "icon" }),
            _createElementVNode("div", null, [
              _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.team.userScore?.finishedQuizzes), 1),
              _createElementVNode("span", _hoisted_11, "/" + _toDisplayString(_ctx.team.userScore?.totalQuizzes), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, _toDisplayString(_unref(i18n).correctAnswers), 1),
          _createElementVNode("div", _hoisted_14, [
            _createVNode(_unref(CursorArrowRippleIcon), { class: "icon" }),
            _createElementVNode("span", _hoisted_15, _toDisplayString(answerPercent(_ctx.team)), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(i18n).competitionRank), 1),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", null, [
              _createVNode(_unref(AcademicCapIcon), { class: "icon" })
            ]),
            _createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.team.userScore?.totalRank == 0 ? "-" : (_ctx.team.userScore?.totalRank + '.')), 1)
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})