import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "profile-unit grid grid-cols-1 gap-y-2" }
const _hoisted_2 = {
  class: "link-box mt-2",
  href: "/pdf/podminky-uzivani.pdf"
}

import {useVersionStore} from "@/stores/version";
import PageLayout from "@/components/PageLayout.vue";
import {onMounted} from "vue";
import {i18n} from "@/services/support/i18n";
import {ArrowDownTrayIcon} from "@heroicons/vue/24/outline";


export default /*@__PURE__*/_defineComponent({
  __name: 'TermsAndConditionsPage',
  setup(__props) {

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageLayout, null, {
    default: _withCtx(() => [
      _cache[0] || (_cache[0] = _createElementVNode("h1", null, "Pravidla a podmínky", -1)),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("a", _hoisted_2, [
          _createVNode(_unref(ArrowDownTrayIcon), { class: "h-5 w-5 text-cez-color" }),
          _createElementVNode("span", null, _toDisplayString(_unref(i18n).termsAndConditions), 1)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})