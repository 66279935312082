import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pb-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "head-part" }
const _hoisted_5 = { class: "text-xs" }
const _hoisted_6 = { class: "footer-part" }
const _hoisted_7 = { class: "icon-box" }
const _hoisted_8 = { class: "icon-box" }

import {BeakerIcon, BoltIcon} from '@heroicons/vue/24/outline'

import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingError from "@/components/LoadingError.vue";
import {niceQuizStateName, QuizState} from "@/services/contracts/QuizState";
import {i18n} from "@/services/support/i18n";
import {onMounted} from "vue";
import {useQuizListStore} from "@/stores/quizList";
import {useSelectedQuizStore} from "@/stores/selectedQuiz";
import {IQuizListItem} from "@/services/contracts/IQuizListItem";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeQuizList',
  setup(__props) {

const quizListStore = useQuizListStore();
const selectedQuizStore = useSelectedQuizStore();

onMounted(() => {
  quizListStore.fetch();
})

function quizClick(quiz: IQuizListItem) {
  selectedQuizStore.set(quiz)
}


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_unref(i18n).quizzes), 1),
    _createVNode(LoadingIndicator, {
      "show-text": true,
      show: _unref(quizListStore).loadingState == _unref(LoadingState).LOADING
    }, null, 8, ["show"]),
    (_unref(quizListStore).loadingState == _unref(LoadingState).OK)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_unref(quizListStore).quizList.length < 1)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(i18n).noQuizzesAvailable), 1))
            : _createCommentVNode("", true),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(quizListStore).quizList, (quiz) => {
            return (_openBlock(), _createBlock(_component_router_link, {
              to: "/kviz",
              key: quiz.id,
              onClick: ($event: any) => (quizClick(quiz)),
              class: _normalizeClass(["quiz-box", quiz.state == _unref(QuizState).COMPLETED || quiz.state == _unref(QuizState).EXPIRED ? 'finished' : ''])
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", null, _toDisplayString(quiz.roundName) + ", " + _toDisplayString(quiz.competitionName), 1),
                  _createElementVNode("div", _hoisted_5, _toDisplayString(quiz.deadline), 1),
                  _createTextVNode(" " + _toDisplayString(_unref(niceQuizStateName)(quiz.state)), 1)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createVNode(_unref(BeakerIcon), {
                      "aria-hidden": "true",
                      class: "h-7 w-7 text-orange-500"
                    }),
                    _createTextVNode(" " + _toDisplayString(quiz.answerCount) + " / " + _toDisplayString(quiz.questionCount), 1)
                  ]),
                  _createElementVNode("div", _hoisted_8, [
                    _createVNode(_unref(BoltIcon), {
                      "aria-hidden": "true",
                      class: "h-7 w-7 bg-orange-500 rounded-full p-1"
                    }),
                    _createTextVNode(" " + _toDisplayString(quiz.achievedPoints) + " / " + _toDisplayString(quiz.maxPoints), 1)
                  ])
                ])
              ]),
              _: 2
            }, 1032, ["onClick", "class"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_unref(quizListStore).loadingState == _unref(LoadingState).ERROR)
      ? (_openBlock(), _createBlock(LoadingError, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})