<script setup lang="ts">

import MyInput from "@/components/MyInput.vue";
import {ArrowDownTrayIcon, ArrowUpCircleIcon} from "@heroicons/vue/24/outline";
import MyButton from "@/components/MyButton.vue";
import {useExoUserStore} from "@/stores/exouser";
import {computed, onMounted, Ref, ref} from "vue";
import {IExoUser} from "@/services/contracts/IExoUser";
import {useNotificationStore} from "@/stores/notification";
import {i18n} from "@/services/support/i18n";
import router from "@/router";
import MyToolTip from "@/components/MyToolTip.vue";

const exoUserStore = useExoUserStore();
const notificationStore = useNotificationStore();

const changed = ref(false);
const imageChanged = ref(false);
const busy = ref(false);
const invalid = computed(() => {
  return exoUser.value.firstName == ''
      || exoUser.value.lastName == ''
      || exoUser.value.nickname == '';
});
const image = ref();
const fileInput = ref();
const preferredEmail = ref();
const firstName = ref();
const lastName = ref();
const nickname = ref();

const participationTextVisible = ref(false);
const termsAndPrivacyTextVisible = ref(false);
const contactTextVisible = ref(false)

const exoUser: Ref<IExoUser> = ref({
  id: 0,
  ssoId: '',
  firstName: '',
  lastName: '',
  nickname: '',
  preferredEmail: '',
  profileImageData: undefined,
  ssoUsername: null,
  email: null,

  participationConsent: false,
  termsAndPrivacyConsent: false,
  contactConsent: false,
  photoConsent: false,
});

const accountName = computed(() => {
  if (exoUserStore.exoUser.ssoId.startsWith('google')) {
    return 'Google | ' + exoUserStore.exoUser.ssoUsername;
  } else if (exoUserStore.exoUser.ssoId.startsWith('apple')) {
    return 'Apple | ' + exoUserStore.exoUser.ssoUsername;
  }
  return 'Email | ' + exoUserStore.exoUser.ssoUsername;
})

onMounted(() => {

  exoUserStore.silentFetch()?.finally(() => {

    const src = exoUserStore.exoUser;
    const dst = exoUser.value;

    dst.id = src.id;
    dst.ssoId = src.ssoId;
    dst.ssoUsername = src.ssoUsername;

    dst.firstName = src.firstName;
    dst.lastName = src.lastName;
    dst.nickname = src.nickname;

    dst.preferredEmail = src.preferredEmail;
    dst.email = src.email;
    dst.profileImageData = src.profileImageData ? src.profileImageData : "/img/logo.png";

    dst.participationConsent = src.participationConsent;
    dst.termsAndPrivacyConsent = src.termsAndPrivacyConsent;
    dst.contactConsent = src.contactConsent;
    dst.photoConsent = src.photoConsent;

    exoUserStore.changed = false;
    changed.value = false;
    imageChanged.value = false;

    checkFirstName();
    checkLastName();
    checkNickname();
  });
});

async function saveChanges() {

  const eu: IExoUser = {
    id: exoUser.value.id,
    ssoId: exoUser.value.ssoId,
    firstName: exoUser.value.firstName,
    lastName: exoUser.value.lastName,
    nickname: exoUser.value.nickname,
    preferredEmail: exoUser.value.preferredEmail,
    profileImageData: imageChanged.value ? exoUser.value.profileImageData : undefined,
    ssoUsername: null,
    email: null,

    participationConsent: exoUser.value.participationConsent,
    termsAndPrivacyConsent: exoUser.value.termsAndPrivacyConsent,
    contactConsent: exoUser.value.contactConsent,
    photoConsent: exoUser.value.photoConsent,
  };

  busy.value = true;

  try {
    await exoUserStore.save(eu);

    exoUserStore.changed = false;
    changed.value = false;
    imageChanged.value = false;

    await router.push('/');
  } catch (error) {
    notificationStore.show(i18n.error, i18n.failedToSaveChanges);
  } finally {
    busy.value = false;
  }
}

function cropAndResizeImage(imgElement: HTMLImageElement, outputSize = 256): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = outputSize;
  canvas.height = outputSize;

  const imgWidth = imgElement.naturalWidth;
  const imgHeight = imgElement.naturalHeight;

  const cropSize = Math.min(imgWidth, imgHeight);
  const cropX = (imgWidth - cropSize) / 2;
  const cropY = (imgHeight - cropSize) / 2;

  ctx.drawImage(imgElement, cropX, cropY, cropSize, cropSize, 0, 0, outputSize, outputSize);

  return canvas.toDataURL();
}

function loadFromFile(ev: any) {
  const files = ev.target.files;

  if (files && files.length) {
    const reader = new FileReader();

    reader.onload = function () {
      image.value.title = files[0].name;

      const tmpImg = document.createElement('img') as HTMLImageElement;

      tmpImg.onload = function () {
        exoUser.value.profileImageData = cropAndResizeImage(tmpImg);

        exoUserStore.changed = true;
        changed.value = true;
        imageChanged.value = true;
      }

      tmpImg.src = reader.result as string;
    }

    reader.readAsDataURL(files[0]);
  }
}

function checkFirstName(e = undefined) {
  if (exoUser.value.firstName == '') {
    firstName.value.setValid(false, i18n.required);
  } else {
    firstName.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function checkLastName(e = undefined) {
  if (exoUser.value.lastName == '') {
    lastName.value.setValid(false, i18n.required);
  } else {
    lastName.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function checkNickname(e = undefined) {
  if (exoUser.value.nickname == '') {
    nickname.value.setValid(false, i18n.required);
  } else {
    nickname.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function retry() {
  window.location.href = '/profil';
}
</script>

<template>
  <div class="profile-unit">
    <div class="pb-1 flex flex-col items-center relative">
      <img ref="image"
           class="inline-block rounded-full border-cez-color border-2 profile-image"
           :src="exoUser.profileImageData"
           alt=""/>
      <input @change="loadFromFile" ref="fileInput" type="file" hidden/>
      <div class="w-10 h-10 bg-cez-color rounded-full cursor-pointer text-white"
           style="margin-top: -50px; margin-left: 160px;"
           :title="i18n.loadImage" @click="fileInput.click()">
        <ArrowUpCircleIcon/>
      </div>
      <MyToolTip style="margin-left: -140px;">Pokud&nbsp;si&nbsp;nahraješ&nbsp;profilovou fotografii, bude Tě provázet
        v&nbsp;žebříčcích skóre.
      </MyToolTip>
    </div>

    <div class="grid grid-cols-1 gap-y-2 ">
      <MyInput :label="i18n.account" disabled type="text"
               v-model="accountName" name="ssoUsername"/>

      <MyInput :label="i18n.email" disabled type="text" ref="preferredEmail"
               v-model="exoUser.preferredEmail" name="preferredEmail"/>

      <div class="flex gap-1 items-center justify-between" v-if="!exoUserStore.isEmailVerified">
        <div class="mb-1 text-red-700 mr-2">{{ i18n.emailNotVerified }}</div>
        <MyButton @click="retry">{{ i18n.refreshVerificationState }}</MyButton>
      </div>

      <MyInput :label="i18n.firstName" type="text" ref="firstName"
               @input="checkFirstName"
               v-model="exoUser.firstName" name="firstName"
               tool-tip="Jednoznačná&nbsp;identifikace&nbsp;a&nbsp;kontrola správnosti přiřazení do soutěžní kategorie. Správnost registrace bude před začátkem soutěže ověřena."/>

      <MyInput :label="i18n.lastName" type="text" ref="lastName"
               @input="checkLastName"
               v-model="exoUser.lastName" name="lastName"
               tool-tip="Jednoznačná&nbsp;identifikace&nbsp;a&nbsp;kontrola správnosti přiřazení do soutěžní kategorie. Správnost registrace bude před začátkem soutěže ověřena."/>

      <MyInput :label="i18n.nickname" type="text" ref="nickname"
               @input="checkNickname"
               v-model="exoUser.nickname" name="nickname"
               tool-tip="Údaj&nbsp;bude&nbsp;zobrazen&nbsp;v&nbsp;žebříčcích Tvé úspěšnosti."/>

      <div class="py-3">
        <div class="flex gap-2">

          <input type="checkbox" v-model="exoUser.participationConsent" @click="changed = true"
                 class="appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"/>
          <label>
            <span>
              Souhlasím s mou účastí ve vzdělávací aplikaci společnosti ČEZ Distribuce, a. s., Prokop Diviš, a
              potvrzuji dosažení věku minimálně 15 let.
            </span>
            <span class="rounded align-text-top p-0.5 text-[0.5rem] bg-red-600 text-white">Povinný souhlas</span>
          </label>
        </div>

        <transition enter-active-class="transition ease-in duration-100"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-out duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
          <div class="text-content" v-show="participationTextVisible">
            <p>
              Mám zájem zapojit se do soutěže pořádané společností ČEZ Distribuce, a. s., v rámci vzdělávací webové
              aplikace Prokop Diviš, a proto souhlasím, aby ČEZ Distribuce, a. s., se sídlem Děčín, Děčín IV-Podmokly,
              Teplická 874/8, PSČ 405 02, IČO 24729035 (dále jen „ČEZ Distribuce“), jakožto správce osobních údajů, dále
              zpracovával mé osobní údaje, zejména kontaktní, které jsem uvedl v rámci registrace, a dále údaje získané
              v rámci mé účasti v soutěži, a to pro účely zajištění řádného průběhu soutěže, jejího následného
              vyhodnocení a udělení cen výhercům podle nastavených pravidel.
            </p>
            <p>
              Souhlas mohu kdykoliv odvolat (přímo v aplikaci nebo emailem na: zuzana.donne@cezdistribuce.cz).
            </p>
            <p>
              Osobní údaje budou zpřístupněny pouze oprávněným zaměstnancům společnosti a dodavatelské společnosti,
              která aplikaci provozuje a vyvíjí, a to pouze v míře nezbytné pro účely zpracování.
            </p>
          </div>
        </transition>

        <div class="relative flex justify-center pt-1">
        <span @click="participationTextVisible = !participationTextVisible" class="text-cez-color cursor-pointer"
              v-text="participationTextVisible ? 'Zobrazit méně' : 'Zobrazit více'"></span>
        </div>

      </div>

      <div class="py-3">
        <div class="flex gap-2">
          <input type="checkbox" v-model="exoUser.termsAndPrivacyConsent" @click="changed = true"
                 class="appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"/>
          <label>
            <span>
            Souhlasím se zněním podmínek užívání webové aplikace Prokop Diviš, a beru na vědomí pravidla
            zpracování osobních údajů.
            </span>
            <span class="rounded align-text-top p-0.5 text-[0.5rem] bg-red-600 text-white">Povinný souhlas</span>
          </label>
        </div>

        <div class="relative flex justify-center pt-1">
          <a class="link-box flex" href="/pdf/podminky-uzivani.pdf" target="_blank">
            <ArrowDownTrayIcon class="h-5 w-5 mr-2 text-cez-color"/>
            <span class="text-cez-color">{{ i18n.termsAndConditions }}</span>
          </a>
        </div>
      </div>

      <div class="py-3">
        <div class="flex gap-2">
          <input type="checkbox" v-model="exoUser.contactConsent" @click="changed = true"
                 class="appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"/>
          <label>
            Souhlasím, abyste mě kontaktovali i po ukončení mého zapojení do projektu Prokop Diviš.
          </label>
        </div>

        <transition enter-active-class="transition ease-in duration-100"
                    enter-from-class="opacity-0"
                    enter-to-class="opacity-100"
                    leave-active-class="transition ease-out duration-100"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
          <div class="text-content" v-show="contactTextVisible">
            <p>
              Chci být dále v kontaktu se společností ČEZ Distribuce a mám zájem na uplatnění v budoucnu u dané
              společnosti, a proto souhlasím, aby ČEZ Distribuce, jakožto správce osobních údajů, dále zpracovával po
              skončení mé účasti v soutěžním ročníku Prokop Diviš mé osobní údaje, zejména kontaktní, které jsem uvedl
              v&nbsp;rámci registrace, a to pro účely kontaktování a nabízení pracovních příležitostí, případně vhodných
              odborných praxí a stáží.
            </p>
            <p>
              Souhlas uděluji na dobu tří let.
            </p>
            <p>
              Souhlas mohu kdykoliv odvolat (přímo v aplikaci nebo emailem na: zuzana.donne@cezdistribuce.cz)
            </p>
            <p>
              Osobní údaje budou zpřístupněny pouze oprávněným zaměstnancům společnosti, a to pouze v míře nezbytné pro
              účely zpracování.
            </p>
          </div>
        </transition>

        <div class="relative flex justify-center pt-1">
        <span @click="contactTextVisible = !contactTextVisible" class="text-cez-color cursor-pointer"
              v-text="contactTextVisible ? 'Zobrazit méně' : 'Zobrazit více'"></span>
        </div>

      </div>

      <div class="py-3">
        <div class="flex gap-2">
          <input type="checkbox" v-model="exoUser.photoConsent" @click="changed = true"
                 class="appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"/>
          <label>Souhlasím s použitím profilové fotografie v žebříčcích s hodnocením.</label>
        </div>
      </div>

    </div>

    <div class="pt-4">
      <MyButton ref="save" @click="saveChanges" :busy="busy" :disabled="invalid || !changed">{{ i18n.save }}</MyButton>
    </div>
  </div>
</template>

<style scoped>
.profile-image {
  width: 256px;
  height: 256px;
}

.text-content {
  @apply text-gray-400 text-sm
}
</style>