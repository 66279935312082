import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelRadio as _vModelRadio, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "question-progress-line" }
const _hoisted_3 = { class: "icon-box" }
const _hoisted_4 = { class: "whitespace-nowrap w-10" }
const _hoisted_5 = { class: "w-full bg-gray-600 rounded-3xl h-2.5" }
const _hoisted_6 = { class: "icon-box" }
const _hoisted_7 = { class: "whitespace-nowrap w-10" }
const _hoisted_8 = ["id", "value"]
const _hoisted_9 = ["for"]
const _hoisted_10 = { class: "button-line" }

import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon, ClockIcon} from '@heroicons/vue/24/outline';
import {computed, onMounted, Ref, ref} from "vue";
import {millisecondsToString} from "@/services/support/timeformat";
import MyMathjax from "@/components/MyMathjax.vue";
import {i18n} from "../services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionDetail',
  props: {
    quiz: {}
  },
  emits: ["validateQuestionButtonClick"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const selectedChoice = ref(0);
const timer = ref();
const timerData: Ref<{
  maxResponseTime: number,
  maxPoints: number,
  answerTime: number,
  elapsedTime: number,
  points: number
}> = ref({maxResponseTime: 0, maxPoints: 0, answerTime: 0, elapsedTime: 0, points: 0});

const remainingTime = computed(() => {
  return timerData.value.maxResponseTime - timerData.value.elapsedTime;
})

const remainingTimeString = computed(() => {
  return millisecondsToString(remainingTime.value < 0 ? 0 : remainingTime.value);
})

const progress = computed(() => {
  const x = timerData.value.elapsedTime * 100 / timerData.value.maxResponseTime;
  return "width:" + (100 - (x > 100 ? 100 : x)) + "%";
})

const remainingPoints = computed(() => {
  if (remainingTime.value < 0) {
    return 0;
  }
  if (remainingTime.value > timerData.value.answerTime) {
    return timerData.value.maxPoints;
  }
  return Math.ceil((remainingTime.value / timerData.value.answerTime) * timerData.value.maxPoints);
})

function validateQuestionButtonClick() {
  emit('validateQuestionButtonClick', selectedChoice.value)
}

function timerCountDown() {
  timerData.value.elapsedTime = timerData.value.elapsedTime + 100;
  if (remainingTime.value < -1000) {
    clearInterval(timer.value);
  }
}

onMounted(() => {
console.log("onMounted QuestionDetail");
  if (props.quiz.answer != null) {
    if (props.quiz.answer.maxResponseTime != null) {
      timerData.value.maxResponseTime = props.quiz.answer.maxResponseTime;
    }
    if (props.quiz.answer.maxPoints != null) {
      timerData.value.maxPoints = props.quiz.answer.maxPoints;
    }
    if (props.quiz.answer.answerTime != null) {
      timerData.value.answerTime = props.quiz.answer.answerTime;
    }
    if (props.quiz.answer.elapsedTime != null) {
      timerData.value.elapsedTime = props.quiz.answer.elapsedTime;
    }
    if (props.quiz.answer.points != null) {
      timerData.value.points = props.quiz.answer.points;
    }
  }

  timer.value = setInterval(timerCountDown, 100);
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(MyMathjax),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(BoltIcon), {
          "aria-hidden": "true",
          class: "h-6 w-6 bg-orange-500 rounded-full p-1"
        }),
        _createElementVNode("span", _hoisted_4, _toDisplayString(remainingPoints.value) + " b.", 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          class: "bg-green-600 h-2.5 rounded",
          style: _normalizeStyle(progress.value)
        }, null, 4)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(ClockIcon), {
          "aria-hidden": "true",
          class: "h-7 w-7 text-orange-500"
        }),
        _createElementVNode("span", _hoisted_7, _toDisplayString(remainingTimeString.value), 1)
      ])
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.quiz.answer?.questionText), 1),
    _createElementVNode("form", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.quiz.answer?.questionChoices, (choice) => {
        return (_openBlock(), _createElementBlock("div", {
          key: choice.id,
          class: "block my-3"
        }, [
          _withDirectives(_createElementVNode("input", {
            type: "radio",
            class: "hidden",
            name: "choice",
            id: 'choice-'+choice.id,
            value: choice.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((selectedChoice).value = $event))
          }, null, 8, _hoisted_8), [
            [_vModelRadio, selectedChoice.value]
          ]),
          _createElementVNode("label", {
            for: 'choice-'+choice.id,
            class: "flex p-3 w-full bg-gray-600 rounded-md"
          }, [
            _cache[1] || (_cache[1] = _createElementVNode("span", { class: "border border-white rounded-full mr-2 w-5 h-5 mt-0.5 flex items-center justify-center shrink-0" }, [
              _createElementVNode("span", { class: "block bg-white rounded-full w-3 h-3" })
            ], -1)),
            _createElementVNode("span", null, _toDisplayString(choice.text), 1)
          ], 8, _hoisted_9)
        ]))
      }), 128))
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createVNode(MyButton, {
        onClick: validateQuestionButtonClick,
        disabled: selectedChoice.value <= 0
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(i18n).checkAnswer), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}
}

})