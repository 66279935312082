import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vShow as _vShow, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "profile-unit" }
const _hoisted_2 = { class: "pb-1 flex flex-col items-center relative" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["title"]
const _hoisted_5 = { class: "grid grid-cols-1 gap-y-2" }
const _hoisted_6 = {
  key: 0,
  class: "flex gap-1 items-center justify-between"
}
const _hoisted_7 = { class: "mb-1 text-red-700 mr-2" }
const _hoisted_8 = { class: "py-3" }
const _hoisted_9 = { class: "flex gap-2" }
const _hoisted_10 = { class: "text-content" }
const _hoisted_11 = { class: "relative flex justify-center pt-1" }
const _hoisted_12 = ["textContent"]
const _hoisted_13 = { class: "py-3" }
const _hoisted_14 = { class: "flex gap-2" }
const _hoisted_15 = { class: "relative flex justify-center pt-1" }
const _hoisted_16 = {
  class: "link-box flex",
  href: "/pdf/podminky-uzivani.pdf",
  target: "_blank"
}
const _hoisted_17 = { class: "text-cez-color" }
const _hoisted_18 = { class: "py-3" }
const _hoisted_19 = { class: "flex gap-2" }
const _hoisted_20 = { class: "text-content" }
const _hoisted_21 = { class: "relative flex justify-center pt-1" }
const _hoisted_22 = ["textContent"]
const _hoisted_23 = { class: "py-3" }
const _hoisted_24 = { class: "flex gap-2" }
const _hoisted_25 = { class: "pt-4" }

import MyInput from "@/components/MyInput.vue";
import {ArrowDownTrayIcon, ArrowUpCircleIcon} from "@heroicons/vue/24/outline";
import MyButton from "@/components/MyButton.vue";
import {useExoUserStore} from "@/stores/exouser";
import {computed, onMounted, Ref, ref} from "vue";
import {IExoUser} from "@/services/contracts/IExoUser";
import {useNotificationStore} from "@/stores/notification";
import {i18n} from "@/services/support/i18n";
import router from "@/router";
import MyToolTip from "@/components/MyToolTip.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileAccount',
  setup(__props) {


const exoUserStore = useExoUserStore();
const notificationStore = useNotificationStore();

const changed = ref(false);
const imageChanged = ref(false);
const busy = ref(false);
const invalid = computed(() => {
  return exoUser.value.firstName == ''
      || exoUser.value.lastName == ''
      || exoUser.value.nickname == '';
});
const image = ref();
const fileInput = ref();
const preferredEmail = ref();
const firstName = ref();
const lastName = ref();
const nickname = ref();

const participationTextVisible = ref(false);
const termsAndPrivacyTextVisible = ref(false);
const contactTextVisible = ref(false)

const exoUser: Ref<IExoUser> = ref({
  id: 0,
  ssoId: '',
  firstName: '',
  lastName: '',
  nickname: '',
  preferredEmail: '',
  profileImageData: undefined,
  ssoUsername: null,
  email: null,

  participationConsent: false,
  termsAndPrivacyConsent: false,
  contactConsent: false,
  photoConsent: false,
});

const accountName = computed(() => {
  if (exoUserStore.exoUser.ssoId.startsWith('google')) {
    return 'Google | ' + exoUserStore.exoUser.ssoUsername;
  } else if (exoUserStore.exoUser.ssoId.startsWith('apple')) {
    return 'Apple | ' + exoUserStore.exoUser.ssoUsername;
  }
  return 'Email | ' + exoUserStore.exoUser.ssoUsername;
})

onMounted(() => {

  exoUserStore.silentFetch()?.finally(() => {

    const src = exoUserStore.exoUser;
    const dst = exoUser.value;

    dst.id = src.id;
    dst.ssoId = src.ssoId;
    dst.ssoUsername = src.ssoUsername;

    dst.firstName = src.firstName;
    dst.lastName = src.lastName;
    dst.nickname = src.nickname;

    dst.preferredEmail = src.preferredEmail;
    dst.email = src.email;
    dst.profileImageData = src.profileImageData ? src.profileImageData : "/img/logo.png";

    dst.participationConsent = src.participationConsent;
    dst.termsAndPrivacyConsent = src.termsAndPrivacyConsent;
    dst.contactConsent = src.contactConsent;
    dst.photoConsent = src.photoConsent;

    exoUserStore.changed = false;
    changed.value = false;
    imageChanged.value = false;

    checkFirstName();
    checkLastName();
    checkNickname();
  });
});

async function saveChanges() {

  const eu: IExoUser = {
    id: exoUser.value.id,
    ssoId: exoUser.value.ssoId,
    firstName: exoUser.value.firstName,
    lastName: exoUser.value.lastName,
    nickname: exoUser.value.nickname,
    preferredEmail: exoUser.value.preferredEmail,
    profileImageData: imageChanged.value ? exoUser.value.profileImageData : undefined,
    ssoUsername: null,
    email: null,

    participationConsent: exoUser.value.participationConsent,
    termsAndPrivacyConsent: exoUser.value.termsAndPrivacyConsent,
    contactConsent: exoUser.value.contactConsent,
    photoConsent: exoUser.value.photoConsent,
  };

  busy.value = true;

  try {
    await exoUserStore.save(eu);

    exoUserStore.changed = false;
    changed.value = false;
    imageChanged.value = false;

    await router.push('/');
  } catch (error) {
    notificationStore.show(i18n.error, i18n.failedToSaveChanges);
  } finally {
    busy.value = false;
  }
}

function cropAndResizeImage(imgElement: HTMLImageElement, outputSize = 256): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

  canvas.width = outputSize;
  canvas.height = outputSize;

  const imgWidth = imgElement.naturalWidth;
  const imgHeight = imgElement.naturalHeight;

  const cropSize = Math.min(imgWidth, imgHeight);
  const cropX = (imgWidth - cropSize) / 2;
  const cropY = (imgHeight - cropSize) / 2;

  ctx.drawImage(imgElement, cropX, cropY, cropSize, cropSize, 0, 0, outputSize, outputSize);

  return canvas.toDataURL();
}

function loadFromFile(ev: any) {
  const files = ev.target.files;

  if (files && files.length) {
    const reader = new FileReader();

    reader.onload = function () {
      image.value.title = files[0].name;

      const tmpImg = document.createElement('img') as HTMLImageElement;

      tmpImg.onload = function () {
        exoUser.value.profileImageData = cropAndResizeImage(tmpImg);

        exoUserStore.changed = true;
        changed.value = true;
        imageChanged.value = true;
      }

      tmpImg.src = reader.result as string;
    }

    reader.readAsDataURL(files[0]);
  }
}

function checkFirstName(e = undefined) {
  if (exoUser.value.firstName == '') {
    firstName.value.setValid(false, i18n.required);
  } else {
    firstName.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function checkLastName(e = undefined) {
  if (exoUser.value.lastName == '') {
    lastName.value.setValid(false, i18n.required);
  } else {
    lastName.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function checkNickname(e = undefined) {
  if (exoUser.value.nickname == '') {
    nickname.value.setValid(false, i18n.required);
  } else {
    nickname.value.setValid(true, '');
    if (e != undefined) {
      exoUserStore.changed = true;
      changed.value = true;
    }
  }
}

function retry() {
  window.location.href = '/profil';
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        ref_key: "image",
        ref: image,
        class: "inline-block rounded-full border-cez-color border-2 profile-image",
        src: exoUser.value.profileImageData,
        alt: ""
      }, null, 8, _hoisted_3),
      _createElementVNode("input", {
        onChange: loadFromFile,
        ref_key: "fileInput",
        ref: fileInput,
        type: "file",
        hidden: ""
      }, null, 544),
      _createElementVNode("div", {
        class: "w-10 h-10 bg-cez-color rounded-full cursor-pointer text-white",
        style: {"margin-top":"-50px","margin-left":"160px"},
        title: _unref(i18n).loadImage,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (fileInput.value.click()))
      }, [
        _createVNode(_unref(ArrowUpCircleIcon))
      ], 8, _hoisted_4),
      _createVNode(MyToolTip, { style: {"margin-left":"-140px"} }, {
        default: _withCtx(() => _cache[16] || (_cache[16] = [
          _createTextVNode("Pokud si nahraješ profilovou fotografii, bude Tě provázet v žebříčcích skóre. ")
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(MyInput, {
        label: _unref(i18n).account,
        disabled: "",
        type: "text",
        modelValue: accountName.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((accountName).value = $event)),
        name: "ssoUsername"
      }, null, 8, ["label", "modelValue"]),
      _createVNode(MyInput, {
        label: _unref(i18n).email,
        disabled: "",
        type: "text",
        ref_key: "preferredEmail",
        ref: preferredEmail,
        modelValue: exoUser.value.preferredEmail,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((exoUser.value.preferredEmail) = $event)),
        name: "preferredEmail"
      }, null, 8, ["label", "modelValue"]),
      (!_unref(exoUserStore).isEmailVerified)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(i18n).emailNotVerified), 1),
            _createVNode(MyButton, { onClick: retry }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(i18n).refreshVerificationState), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createVNode(MyInput, {
        label: _unref(i18n).firstName,
        type: "text",
        ref_key: "firstName",
        ref: firstName,
        onInput: checkFirstName,
        modelValue: exoUser.value.firstName,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((exoUser.value.firstName) = $event)),
        name: "firstName",
        "tool-tip": "Jednoznačná identifikace a kontrola správnosti přiřazení do soutěžní kategorie. Správnost registrace bude před začátkem soutěže ověřena."
      }, null, 8, ["label", "modelValue"]),
      _createVNode(MyInput, {
        label: _unref(i18n).lastName,
        type: "text",
        ref_key: "lastName",
        ref: lastName,
        onInput: checkLastName,
        modelValue: exoUser.value.lastName,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((exoUser.value.lastName) = $event)),
        name: "lastName",
        "tool-tip": "Jednoznačná identifikace a kontrola správnosti přiřazení do soutěžní kategorie. Správnost registrace bude před začátkem soutěže ověřena."
      }, null, 8, ["label", "modelValue"]),
      _createVNode(MyInput, {
        label: _unref(i18n).nickname,
        type: "text",
        ref_key: "nickname",
        ref: nickname,
        onInput: checkNickname,
        modelValue: exoUser.value.nickname,
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((exoUser.value.nickname) = $event)),
        name: "nickname",
        "tool-tip": "Údaj bude zobrazen v žebříčcích Tvé úspěšnosti."
      }, null, 8, ["label", "modelValue"]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((exoUser.value.participationConsent) = $event)),
            onClick: _cache[7] || (_cache[7] = ($event: any) => (changed.value = true)),
            class: "appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"
          }, null, 512), [
            [_vModelCheckbox, exoUser.value.participationConsent]
          ]),
          _cache[17] || (_cache[17] = _createElementVNode("label", null, [
            _createElementVNode("span", null, " Souhlasím s mou účastí ve vzdělávací aplikaci společnosti ČEZ Distribuce, a. s., Prokop Diviš, a potvrzuji dosažení věku minimálně 15 let. "),
            _createElementVNode("span", { class: "rounded align-text-top p-0.5 text-[0.5rem] bg-red-600 text-white" }, "Povinný souhlas")
          ], -1))
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-in duration-100",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "transition ease-out duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_10, _cache[18] || (_cache[18] = [
              _createElementVNode("p", null, " Mám zájem zapojit se do soutěže pořádané společností ČEZ Distribuce, a. s., v rámci vzdělávací webové aplikace Prokop Diviš, a proto souhlasím, aby ČEZ Distribuce, a. s., se sídlem Děčín, Děčín IV-Podmokly, Teplická 874/8, PSČ 405 02, IČO 24729035 (dále jen „ČEZ Distribuce“), jakožto správce osobních údajů, dále zpracovával mé osobní údaje, zejména kontaktní, které jsem uvedl v rámci registrace, a dále údaje získané v rámci mé účasti v soutěži, a to pro účely zajištění řádného průběhu soutěže, jejího následného vyhodnocení a udělení cen výhercům podle nastavených pravidel. ", -1),
              _createElementVNode("p", null, " Souhlas mohu kdykoliv odvolat (přímo v aplikaci nebo emailem na: zuzana.donne@cezdistribuce.cz). ", -1),
              _createElementVNode("p", null, " Osobní údaje budou zpřístupněny pouze oprávněným zaměstnancům společnosti a dodavatelské společnosti, která aplikaci provozuje a vyvíjí, a to pouze v míře nezbytné pro účely zpracování. ", -1)
            ]), 512), [
              [_vShow, participationTextVisible.value]
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("span", {
            onClick: _cache[8] || (_cache[8] = ($event: any) => (participationTextVisible.value = !participationTextVisible.value)),
            class: "text-cez-color cursor-pointer",
            textContent: _toDisplayString(participationTextVisible.value ? 'Zobrazit méně' : 'Zobrazit více')
          }, null, 8, _hoisted_12)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((exoUser.value.termsAndPrivacyConsent) = $event)),
            onClick: _cache[10] || (_cache[10] = ($event: any) => (changed.value = true)),
            class: "appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"
          }, null, 512), [
            [_vModelCheckbox, exoUser.value.termsAndPrivacyConsent]
          ]),
          _cache[19] || (_cache[19] = _createElementVNode("label", null, [
            _createElementVNode("span", null, " Souhlasím se zněním podmínek užívání webové aplikace Prokop Diviš, a beru na vědomí pravidla zpracování osobních údajů. "),
            _createElementVNode("span", { class: "rounded align-text-top p-0.5 text-[0.5rem] bg-red-600 text-white" }, "Povinný souhlas")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("a", _hoisted_16, [
            _createVNode(_unref(ArrowDownTrayIcon), { class: "h-5 w-5 mr-2 text-cez-color" }),
            _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(i18n).termsAndConditions), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((exoUser.value.contactConsent) = $event)),
            onClick: _cache[12] || (_cache[12] = ($event: any) => (changed.value = true)),
            class: "appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"
          }, null, 512), [
            [_vModelCheckbox, exoUser.value.contactConsent]
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("label", null, " Souhlasím, abyste mě kontaktovali i po ukončení mého zapojení do projektu Prokop Diviš. ", -1))
        ]),
        _createVNode(_Transition, {
          "enter-active-class": "transition ease-in duration-100",
          "enter-from-class": "opacity-0",
          "enter-to-class": "opacity-100",
          "leave-active-class": "transition ease-out duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", _hoisted_20, _cache[21] || (_cache[21] = [
              _createElementVNode("p", null, " Chci být dále v kontaktu se společností ČEZ Distribuce a mám zájem na uplatnění v budoucnu u dané společnosti, a proto souhlasím, aby ČEZ Distribuce, jakožto správce osobních údajů, dále zpracovával po skončení mé účasti v soutěžním ročníku Prokop Diviš mé osobní údaje, zejména kontaktní, které jsem uvedl v rámci registrace, a to pro účely kontaktování a nabízení pracovních příležitostí, případně vhodných odborných praxí a stáží. ", -1),
              _createElementVNode("p", null, " Souhlas uděluji na dobu tří let. ", -1),
              _createElementVNode("p", null, " Souhlas mohu kdykoliv odvolat (přímo v aplikaci nebo emailem na: zuzana.donne@cezdistribuce.cz) ", -1),
              _createElementVNode("p", null, " Osobní údaje budou zpřístupněny pouze oprávněným zaměstnancům společnosti, a to pouze v míře nezbytné pro účely zpracování. ", -1)
            ]), 512), [
              [_vShow, contactTextVisible.value]
            ])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("span", {
            onClick: _cache[13] || (_cache[13] = ($event: any) => (contactTextVisible.value = !contactTextVisible.value)),
            class: "text-cez-color cursor-pointer",
            textContent: _toDisplayString(contactTextVisible.value ? 'Zobrazit méně' : 'Zobrazit více')
          }, null, 8, _hoisted_22)
        ])
      ]),
      _createElementVNode("div", _hoisted_23, [
        _createElementVNode("div", _hoisted_24, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((exoUser.value.photoConsent) = $event)),
            onClick: _cache[15] || (_cache[15] = ($event: any) => (changed.value = true)),
            class: "appearance-none rounded border border-gray-300 bg-white text-cez-color focus:ring-cez-color mt-1"
          }, null, 512), [
            [_vModelCheckbox, exoUser.value.photoConsent]
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("label", null, "Souhlasím s použitím profilové fotografie v žebříčcích s hodnocením.", -1))
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_25, [
      _createVNode(MyButton, {
        ref: "save",
        onClick: saveChanges,
        busy: busy.value,
        disabled: invalid.value || !changed.value
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(i18n).save), 1)
        ]),
        _: 1
      }, 8, ["busy", "disabled"])
    ])
  ]))
}
}

})