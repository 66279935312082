import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pt-3"
}
const _hoisted_2 = { class: "score-table" }
const _hoisted_3 = { class: "rank" }
const _hoisted_4 = { class: "points" }
const _hoisted_5 = { key: 0 }

import PageLayout from "@/components/PageLayout.vue";
import {useVersionStore} from "@/stores/version";
import {computed, onMounted, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import LoadingError from "@/components/LoadingError.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {useTeacherScoreStore} from "@/stores/teacherScore";
import {useTeacherCompetitionListStore} from "@/stores/teacherCompetitionList";
import TeacherCompetitionCombo from "@/components/TeacherCompetitionCombo.vue";
import TeacherInstitutionCombo from "@/components/TeacherInstitutionCombo.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TeacherScorePage',
  setup(__props) {

const versionStore = useVersionStore();
const scoreStore = useTeacherScoreStore();
const competitionStore = useTeacherCompetitionListStore();

onMounted(async () => {
  await Promise.all([versionStore.check(true), scoreStore.onMounted()]);
});

watch(() => scoreStore.institution, () => {
  scoreStore.fetch();
});

const isLoading = computed(() => {
  return scoreStore.loadingState == LoadingState.LOADING
      || competitionStore.loadingState == LoadingState.LOADING;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_unref(i18n).teacherScoreBoard), 1),
      _cache[1] || (_cache[1] = _createElementVNode("label", { class: "block text-xs font-medium leading-6 text-gray-400" }, "v soutěži", -1)),
      _createVNode(TeacherCompetitionCombo),
      _cache[2] || (_cache[2] = _createElementVNode("label", { class: "block text-xs font-medium leading-6 text-gray-400 pt-2" }, "v rámci", -1)),
      _createVNode(TeacherInstitutionCombo),
      _cache[3] || (_cache[3] = _createElementVNode("div", { class: "h-3" }, null, -1)),
      _createVNode(LoadingIndicator, {
        show: isLoading.value && _unref(scoreStore).list.items.length == 0,
        class: "pt-2"
      }, null, 8, ["show"]),
      (_unref(scoreStore).loadingState == _unref(LoadingState).ERROR)
        ? (_openBlock(), _createBlock(LoadingError, { key: 0 }))
        : _createCommentVNode("", true),
      (_unref(scoreStore).loadingState == _unref(LoadingState).OK && _unref(scoreStore).list.items.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("table", _hoisted_2, [
              _cache[0] || (_cache[0] = _createElementVNode("thead", null, [
                _createElementVNode("tr", null, [
                  _createElementVNode("th", { class: "rank" }, "Pořadí"),
                  _createElementVNode("th", null, "Student"),
                  _createElementVNode("th", null, "Škola/třída"),
                  _createElementVNode("th", { class: "points" }, "Body")
                ])
              ], -1)),
              _createElementVNode("tbody", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(scoreStore).list.items, (line) => {
                  return (_openBlock(), _createElementBlock("tr", {
                    key: line.idExoUser
                  }, [
                    _createElementVNode("td", _hoisted_3, _toDisplayString(line.rank), 1),
                    _createElementVNode("td", null, _toDisplayString(line.nickname), 1),
                    _createElementVNode("td", null, _toDisplayString(line.teams), 1),
                    _createElementVNode("td", _hoisted_4, _toDisplayString(line.points), 1)
                  ]))
                }), 128))
              ])
            ]),
            (_unref(scoreStore).list.items.length < 1 && _unref(scoreStore).loadingState == _unref(LoadingState).OK)
              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(i18n).noScoresAvailable), 1))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})