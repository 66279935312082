<script setup lang="ts">

import MyInput from "@/components/MyInput.vue";
import {useTeamStore} from "@/stores/team";
import MyButton from "@/components/MyButton.vue";
import {ClockIcon, QuestionMarkCircleIcon, UsersIcon, XCircleIcon} from "@heroicons/vue/24/outline";
import {MembershipState} from "@/services/contracts/MembershipState";
import {ITeam} from "@/services/contracts/ITeam";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import LoadingError from "@/components/LoadingError.vue";
import {useTeamListStore} from "@/stores/teamList";
import {onMounted, ref, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import {useRoute} from "vue-router";

const teamListStore = useTeamListStore();
const teamStore = useTeamStore();
const accessCode = ref('');
const input = ref();
const route = useRoute();

const listClass = ref("");

onMounted(() => {
  teamListStore.fetch();
  focusTeamInput(route.hash);
})

watch(() => route.hash, (newHash) => {
  focusTeamInput(newHash);
});

function focusTeamInput(hash: string) {
  if (hash && hash === "#team" && input.value) {
    document.querySelector('.team-input input')?.focus();
  }
}

async function joinTeam() {
  accessCode.value = accessCode.value.toUpperCase();

  try {
    await teamStore.joinTeam(accessCode.value);
    accessCode.value = "";
    listClass.value = "highlight";
    setTimeout(() => listClass.value = "", 1000);
  } catch {
    // leave access code in input
  }
}

function leaveTeam(team: ITeam) {
  teamStore.leaveTeam(team);
}
</script>

<template>
  <div class="profile-unit" id="team">
    <h1>{{ i18n.teams }}</h1>

    <LoadingSpinner :show="teamListStore.loadingState == LoadingState.LOADING"/>
    <LoadingError v-if="teamListStore.loadingState == LoadingState.ERROR"/>

    <div :class="listClass">
      <div v-for="team in teamListStore.teamState.items" :key="team.id" class="mt-1.5"
           :content="false">
        <div class="flex items-center justify-between">
          <div>
            <ClockIcon v-if="team.state == MembershipState.PENDING" class="h-5 w-5 text-cez-color inline"/>
            <UsersIcon v-if="team.state == MembershipState.ACCEPTED" class="h-5 w-5 text-cez-color inline"/>
            <XCircleIcon v-if="team.state == MembershipState.DENIED" class="h-5 w-5 text-red-600 inline"/>
            <QuestionMarkCircleIcon v-if="team.state == MembershipState.UNKNOWN" class="h-5 w-5 text-red-600 inline"/>

            {{ team.teamName }}, {{ team.institutionName }}, {{ team.competitionName }}
          </div>
          <div class="w-36">
            <MyButton class="leave-button" @click="leaveTeam(team)"
                      :busy="teamStore.isLeavingBusy.value">{{ i18n.leaveTeam }}
            </MyButton>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-2">
      <MyInput class="team-input" ref="input" :label="i18n.accessCode" type="text"
               v-model="accessCode"
               name="accessCode"
               tool-tip="Kód&nbsp;obdržíš&nbsp;od&nbsp;svého&nbsp;vyučujícího a&nbsp;budeš automaticky přiřazen do odpovídající kategorie. Jedná se o&nbsp;kombinaci číslic a písmen."/>
      <MyButton class="mt-2" @click="joinTeam()" :disabled="accessCode == ''"
                :busy="teamStore.isJoiningBusy.value">{{ i18n.joinTeam }}
      </MyButton>
    </div>

  </div>
</template>

<style scoped>
.leave-button {
  @apply py-1.5;
}

.team-input >>> input {
  font-family: monospace;
  font-weight: bold;
  text-transform: uppercase;
}

.highlight {
  animation: highlight-animation 0.75s
}

@keyframes highlight-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
