import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "quiz-header" }
const _hoisted_2 = { class: "h-10 w-10 p-1 bg-orange-500 rounded-full text-sm flex items-center justify-center" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import {onMounted} from "vue";
import {useQuizPseudoStore} from "@/stores/quiz";
import {ArrowLeftIcon} from '@heroicons/vue/24/outline';
import {QuizPageState} from "@/services/contracts/QuizPageState";
import QuestionResult from "@/components/QuestionResult.vue";
import QuestionDetail from "@/components/QuestionDetail.vue";
import QuizDetail from "@/components/QuizDetail.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {i18n} from "@/services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuizPage',
  setup(__props) {

const quizStore = useQuizPseudoStore();

function showQuestionButtonClick() {
  quizStore.openQuestion();
}

function validateQuestionButtonClick(selectedChoice: number) {
  quizStore.sendAnswer(selectedChoice);
}

function nextQuestionButtonClick() {
  showQuestionButtonClick();
}

onMounted(() => {
  quizStore.fetch();
})

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createVNode(_unref(ArrowLeftIcon), { class: "w-6 h-6" })
        ]),
        _: 1
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", null, _toDisplayString(_unref(quizStore).quiz.value?.answerCount) + "/" + _toDisplayString(_unref(quizStore).quiz.value?.questionCount), 1)
      ]),
      _createElementVNode("div", null, _toDisplayString(_unref(quizStore).quiz.value?.roundName) + ", " + _toDisplayString(_unref(quizStore).quiz.value?.competitionName), 1)
    ]),
    _createVNode(LoadingIndicator, {
      ref: "loading",
      "show-text": true,
      show: _unref(quizStore).loadingState.value == _unref(LoadingState).LOADING
    }, null, 8, ["show"]),
    (_unref(quizStore).loadingState.value == _unref(LoadingState).ERROR)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("p", null, _toDisplayString(_unref(i18n).loadingError), 1)
        ]))
      : _createCommentVNode("", true),
    (_unref(quizStore).loadingState.value == _unref(LoadingState).OK)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_unref(quizStore).quiz.value?.pageState == _unref(QuizPageState).INFO)
            ? (_openBlock(), _createBlock(QuizDetail, {
                key: 0,
                quiz: _unref(quizStore).quiz.value,
                onShowQuestionButtonClick: showQuestionButtonClick
              }, null, 8, ["quiz"]))
            : _createCommentVNode("", true),
          (_unref(quizStore).quiz.value?.pageState == _unref(QuizPageState).QUESTION)
            ? (_openBlock(), _createBlock(QuestionDetail, {
                key: 1,
                quiz: _unref(quizStore).quiz.value,
                onValidateQuestionButtonClick: validateQuestionButtonClick
              }, null, 8, ["quiz"]))
            : _createCommentVNode("", true),
          (_unref(quizStore).quiz.value?.pageState == _unref(QuizPageState).QUESTION_RESULT)
            ? (_openBlock(), _createBlock(QuestionResult, {
                key: 2,
                quiz: _unref(quizStore).quiz.value,
                onNextQuestionButtonClick: nextQuestionButtonClick
              }, null, 8, ["quiz"]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})