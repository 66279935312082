import api from "@/services/support/api";
import {IScoreList} from "@/services/contracts/IScoreList";

class TeacherScoreService {

    find(competitionId: number, institutionId: number) {
        return api.get<IScoreList>(`teacher-scores/competition/${competitionId}/institution/${institutionId}`, {});
    }
}

export default new TeacherScoreService();
