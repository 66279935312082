import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "quiz-info" }
const _hoisted_2 = { class: "icon-box" }
const _hoisted_3 = { class: "box-headline" }
const _hoisted_4 = { class: "icon-box" }
const _hoisted_5 = { class: "box-headline" }
const _hoisted_6 = { class: "icon-box" }
const _hoisted_7 = { class: "box-headline" }
const _hoisted_8 = { class: "icon-box" }
const _hoisted_9 = { class: "box-headline" }
const _hoisted_10 = {
  key: 0,
  class: "button-line"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { key: 1 }
const _hoisted_13 = { key: 2 }

import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon, CalendarIcon, ClockIcon, DocumentIcon} from '@heroicons/vue/24/outline';
import {computed, onMounted} from "vue";
import {niceQuizStateName, QuizState} from "@/services/contracts/QuizState";
import {millisecondsToString} from "@/services/support/timeformat";
import {useAnswerListStore} from "@/stores/answerList";
import AnsweredQuestionDetail from "@/components/AnsweredQuestionDetail.vue";
import MyMathjax from "@/components/MyMathjax.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {i18n} from "@/services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuizDetail',
  props: {
    quiz: {}
  },
  emits: ["showQuestionButtonClick"],
  setup(__props: any, { emit: __emit }) {

const props = __props

const emit = __emit

const answerListStore = useAnswerListStore();

const quizFinished = computed(() => {
  return props.quiz.state == QuizState.COMPLETED || props.quiz.state == QuizState.EXPIRED;
})

const buttonText = computed(() => {
  if (props.quiz.state == QuizState.NEW) {
    return i18n.runQuiz;
  }
  if (props.quiz.state == QuizState.IN_PROGRESS) {
    return i18n.runQuiz;
  }
  if (props.quiz.state == QuizState.COMPLETED) {
    return i18n.browseQuiz;
  }
  if (props.quiz.state == QuizState.EXPIRED) {
    return i18n.browseQuiz;
  }
  return "";
})

function showQuestionButtonClick() {
  emit('showQuestionButtonClick')
}

onMounted(() => {
  if (props.quiz.state == QuizState.COMPLETED) {
    answerListStore.fetch();
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_unref(DocumentIcon), {
        "aria-hidden": "true",
        class: "h-7 w-7 text-orange-500"
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, _toDisplayString(_unref(i18n).state), 1),
        _createTextVNode(" " + _toDisplayString(_unref(niceQuizStateName)(_ctx.quiz.state)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(CalendarIcon), {
        "aria-hidden": "true",
        class: "h-7 w-7 text-orange-500"
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_5, _toDisplayString(_unref(i18n).deadline), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.quiz.deadline), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_unref(ClockIcon), {
        "aria-hidden": "true",
        class: "h-7 w-7 text-orange-500"
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(i18n).totalTime), 1),
        _createTextVNode(" " + _toDisplayString(_unref(millisecondsToString)(_ctx.quiz.spendTime)) + " / " + _toDisplayString(_unref(millisecondsToString)(_ctx.quiz.maxResponseTime)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_unref(BoltIcon), {
        "aria-hidden": "true",
        class: "h-7 w-7 bg-orange-500 rounded-full p-1"
      }),
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(i18n).achievedPoints), 1),
        _createTextVNode(" " + _toDisplayString(_ctx.quiz.achievedPoints) + " / " + _toDisplayString(_ctx.quiz.maxPoints), 1)
      ])
    ]),
    (!quizFinished.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createVNode(MyButton, { onClick: showQuestionButtonClick }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(buttonText.value), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (props.quiz.state == _unref(QuizState).COMPLETED)
      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
          (_unref(answerListStore).loadingState == _unref(LoadingState).LOADING)
            ? (_openBlock(), _createBlock(LoadingIndicator, {
                key: 0,
                "show-text": true
              }))
            : _createCommentVNode("", true),
          (_unref(answerListStore).loadingState == _unref(LoadingState).OK)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(MyMathjax),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(answerListStore).answerList, (answer, index) => {
                  return (_openBlock(), _createBlock(AnsweredQuestionDetail, {
                    key: answer.id,
                    answer: answer,
                    "answer-count": _unref(answerListStore).answerList.length,
                    "answer-position": index
                  }, null, 8, ["answer", "answer-count", "answer-position"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_unref(answerListStore).loadingState == _unref(LoadingState).ERROR)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("p", null, _toDisplayString(_unref(i18n).loadingError), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})