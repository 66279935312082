import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "question-result" }
const _hoisted_2 = { class: "flex flex-col items-center gap-3" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "grow-0",
  src: "img/correct.png",
  alt: "Správně"
}
const _hoisted_6 = {
  key: 3,
  class: "grow-0",
  src: "img/wrong.png",
  alt: "Chyba"
}
const _hoisted_7 = { class: "icon-box" }
const _hoisted_8 = { class: "whitespace-nowrap" }
const _hoisted_9 = { class: "button-line" }

import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon} from '@heroicons/vue/24/outline';
import {i18n} from "@/services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'QuestionResult',
  props: {
    quiz: {}
  },
  emits: ["nextQuestionButtonClick"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit

function nextQuestionButtonClick() {
  emit('nextQuestionButtonClick')
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.quiz.answer?.correct)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_unref(i18n).correct), 1))
        : _createCommentVNode("", true),
      (!_ctx.quiz.answer?.correct)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(i18n).incorrect), 1))
        : _createCommentVNode("", true),
      (_ctx.quiz.answer?.correct)
        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
        : _createCommentVNode("", true),
      (!_ctx.quiz.answer?.correct)
        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_unref(BoltIcon), {
          "aria-hidden": "true",
          class: "h-6 w-6 bg-orange-500 rounded-full p-1"
        }),
        _createElementVNode("span", _hoisted_8, "+ " + _toDisplayString(_ctx.quiz.answer?.points) + " b.", 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(MyButton, { onClick: nextQuestionButtonClick }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_unref(i18n).nextQuestion), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})