<script setup lang="ts">
import {useVersionStore} from "@/stores/version";
import LogoutButton from "@/components/LogoutButton.vue";
import ProfileInfoLinks from "@/components/ProfileInfoLinks.vue";
import ProfileAccount from "@/components/ProfileAccount.vue";
import ProfileTeams from "@/components/ProfileTeams.vue";
import {onMounted, ref} from "vue";
import PageHeader from "@/components/PageHeader.vue";
import {HomeIcon} from "@heroicons/vue/24/outline";
import HomeNotificationPopup from "@/components/HomeNotificationPopup.vue";
import {useExoUserStore} from "@/stores/exouser";
import {useRouter} from "vue-router";

const exoUserStore = useExoUserStore();
const router = useRouter();
const popup = ref();

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));

function homeClick(event: Event) {
  if (exoUserStore.changed) {
    console.log('zobrazit varovani o neulozenych zmenach', event);
    popup.value.show();
  } else {
    console.log('zadne zmeny');
    router.push('/');
  }
}

</script>

<template>
  <div class="page-layout">

    <HomeNotificationPopup ref="popup"/>
    <div class="page-header">
    <span class="py-2 flex flex-col items-center gap-2 text-orange-500">
      <HomeIcon aria-hidden="true" class="h-8 w-8 cursor-pointer" @click="homeClick($event)"/>
    </span>
    </div>

    <div class="content">
      <ProfileAccount/>
      <ProfileTeams/>
      <ProfileInfoLinks/>

      <div class="profile-unit">
        <LogoutButton/>
        <div class="text-gray-600 text-sm text-center pt-4">{{ versionStore.version }}</div>
      </div>

    </div>
  </div>
</template>
