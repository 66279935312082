import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }

import {ChatBubbleLeftIcon} from "@heroicons/vue/24/outline";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MyToolTip',
  setup(__props) {

//import {InformationCircleIcon} from "@heroicons/vue/24/outline";
const visible = ref(false);

function toggle(): void {
  if (visible.value) {
    hide();
  } else {
    show();
  }
}

function hide(): void {
  visible.value = false;
}

function show(): void {
  visible.value = true;
  setTimeout(() => {
    hide();
  }, 5000);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "h-6 w-5 text-cez-color cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (toggle()))
    }, "?"),
    _createVNode(_Transition, {
      "enter-active-class": "transition ease-in duration-100",
      "enter-from-class": "opacity-0",
      "enter-to-class": "opacity-100",
      "leave-active-class": "transition ease-out duration-100",
      "leave-from-class": "opacity-100",
      "leave-to-class": "opacity-0"
    }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", {
          class: "bg-gray-900 rounded-lg absolute z-10 rounded-tl-none left-2 top-2 px-3 py-1 text-xs font-medium leading-5 text-gray-400 border border-gray-800",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (hide()))
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 512), [
          [_vShow, visible.value]
        ])
      ]),
      _: 3
    })
  ]))
}
}

})