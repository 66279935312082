import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = { class: "page-header" }
const _hoisted_3 = { class: "py-2 flex flex-col items-center gap-2 text-orange-500" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "profile-unit" }
const _hoisted_6 = { class: "text-gray-600 text-sm text-center pt-4" }

import {useVersionStore} from "@/stores/version";
import LogoutButton from "@/components/LogoutButton.vue";
import ProfileInfoLinks from "@/components/ProfileInfoLinks.vue";
import ProfileAccount from "@/components/ProfileAccount.vue";
import ProfileTeams from "@/components/ProfileTeams.vue";
import {onMounted, ref} from "vue";
import PageHeader from "@/components/PageHeader.vue";
import {HomeIcon} from "@heroicons/vue/24/outline";
import HomeNotificationPopup from "@/components/HomeNotificationPopup.vue";
import {useExoUserStore} from "@/stores/exouser";
import {useRouter} from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfilePage',
  setup(__props) {

const exoUserStore = useExoUserStore();
const router = useRouter();
const popup = ref();

const versionStore = useVersionStore();
onMounted(() => versionStore.check(true));

function homeClick(event: Event) {
  if (exoUserStore.changed) {
    console.log('zobrazit varovani o neulozenych zmenach', event);
    popup.value.show();
  } else {
    console.log('zadne zmeny');
    router.push('/');
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(HomeNotificationPopup, {
      ref_key: "popup",
      ref: popup
    }, null, 512),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createVNode(_unref(HomeIcon), {
          "aria-hidden": "true",
          class: "h-8 w-8 cursor-pointer",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (homeClick($event)))
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(ProfileAccount),
      _createVNode(ProfileTeams),
      _createVNode(ProfileInfoLinks),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(LogoutButton),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_unref(versionStore).version), 1)
      ])
    ])
  ]))
}
}

})