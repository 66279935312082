import {Ref, ref} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {ICompetitionList} from "@/services/contracts/ICompetitionList";
import {defineStore} from "pinia";
import {logError} from "@/services/support/error";
import TeacherCompetitionService from "@/services/TeacherCompetitionService";

export const useTeacherCompetitionListStore
    = defineStore('teacherCompetitions', () => {

    const EMPTY: ICompetitionList = {items: []};
    const loadingState = ref(LoadingState.LOADING);
    const list: Ref<ICompetitionList> = ref(EMPTY);

    async function fetch() {

        console.debug('Fetching teacher competitions...');
        loadingState.value = LoadingState.LOADING;

        try {
            const response = await TeacherCompetitionService.find();
            list.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            list.value = EMPTY;
            loadingState.value = LoadingState.ERROR;
            logError('Failed to fetch teacher competitions', err);
        }
    }

    return {list, loadingState, fetch}
});