<script setup lang="ts">
import PageLayout from "@/components/PageLayout.vue";
import {useVersionStore} from "@/stores/version";
import {computed, onMounted, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import LoadingError from "@/components/LoadingError.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {useTeacherScoreStore} from "@/stores/teacherScore";
import {useTeacherCompetitionListStore} from "@/stores/teacherCompetitionList";
import TeacherCompetitionCombo from "@/components/TeacherCompetitionCombo.vue";
import TeacherInstitutionCombo from "@/components/TeacherInstitutionCombo.vue";

const versionStore = useVersionStore();
const scoreStore = useTeacherScoreStore();
const competitionStore = useTeacherCompetitionListStore();

onMounted(async () => {
  await Promise.all([versionStore.check(true), scoreStore.onMounted()]);
});

watch(() => scoreStore.institution, () => {
  scoreStore.fetch();
});

const isLoading = computed(() => {
  return scoreStore.loadingState == LoadingState.LOADING
      || competitionStore.loadingState == LoadingState.LOADING;
})
</script>

<template>
  <PageLayout>
    <h1>{{ i18n.teacherScoreBoard }}</h1>

    <label class="block text-xs font-medium leading-6 text-gray-400">v soutěži</label>
    <TeacherCompetitionCombo/>

    <label class="block text-xs font-medium leading-6 text-gray-400 pt-2">v rámci</label>
    <TeacherInstitutionCombo/>

    <div class="h-3"></div>

    <LoadingIndicator :show="isLoading && scoreStore.list.items.length == 0" class="pt-2"/>
    <LoadingError v-if="scoreStore.loadingState == LoadingState.ERROR"/>

    <div class="pt-3" v-if="scoreStore.loadingState == LoadingState.OK && scoreStore.list.items.length > 0">
      <table class="score-table">
        <thead>
        <tr>
          <th class="rank">Pořadí</th>
          <th>Student</th>
          <th>Škola/třída</th>
          <th class="points">Body</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="line in scoreStore.list.items" :key="line.idExoUser">
          <td class="rank"> {{ line.rank }}</td>
          <td> {{ line.nickname }}</td>
          <td> {{ line.teams }}</td>
          <td class="points"> {{ line.points }}</td>
        </tr>
        </tbody>
      </table>

      <p v-if="scoreStore.list.items.length < 1 && scoreStore.loadingState == LoadingState.OK">
        {{ i18n.noScoresAvailable }}</p>
    </div>
  </PageLayout>
</template>

<style scoped>
.score-table {
  width: 100%;
  border-collapse: collapse;
}

.score-table td, th {
  border: solid 1px #333;
  color: #ccc;
  text-align: left;
  font-weight: normal;
  font-size: smaller;
  padding: 0.25rem;
}

.score-table .points,
.score-table .rank {
  text-align: right;
}
</style>

