import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex w-full flex-col items-center space-y-4" }
const _hoisted_2 = {
  key: 0,
  class: "pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-red-100 shadow-lg ring-1 ring-black ring-opacity-5"
}
const _hoisted_3 = { class: "p-4" }
const _hoisted_4 = { class: "flex items-start" }
const _hoisted_5 = { class: "flex-shrink-0" }
const _hoisted_6 = { class: "ml-3 w-0 flex-1 pt-0.5" }
const _hoisted_7 = { class: "text-sm font-medium text-gray-900" }
const _hoisted_8 = { class: "mt-1 text-sm text-gray-500" }
const _hoisted_9 = { class: "ml-4 flex flex-shrink-0" }
const _hoisted_10 = ["onClick"]

import {ExclamationTriangleIcon} from '@heroicons/vue/24/outline'
import {XMarkIcon} from '@heroicons/vue/20/solid'
import {useNotificationStore} from "@/stores/notification";
import {ref} from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationPopup',
  setup(__props) {

const el = ref();
const notificationStore = useNotificationStore();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    ref_key: "el",
    ref: el,
    class: "pointer-events-none fixed bottom-0 right-0 inset-0 flex items-end px-4 py-6 z-50"
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(notificationStore).items, (notification) => {
        return (_openBlock(), _createBlock(_Transition, {
          key: notification.id,
          "enter-active-class": "transform ease-out duration-300 transition",
          "enter-from-class": "translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2",
          "enter-to-class": "translate-y-0 opacity-100 sm:translate-x-0",
          "leave-active-class": "transition ease-in duration-100",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            (notification.visible)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_unref(ExclamationTriangleIcon), { class: "h-6 w-6 text-red-600" })
                      ]),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("p", _hoisted_7, _toDisplayString(notification.title), 1),
                        _createElementVNode("p", _hoisted_8, _toDisplayString(notification.message), 1)
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createElementVNode("button", {
                          class: "inline-flex rounded-md bg-red-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                          type: "button",
                          onClick: ($event: any) => {_unref(notificationStore).hide(notification);}
                        }, [
                          _createVNode(_unref(XMarkIcon), { class: "h-5 w-5" })
                        ], 8, _hoisted_10)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ])
  ], 512))
}
}

})