import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex items-center gap-3" }
const _hoisted_2 = { key: 0 }

import {ExclamationTriangleIcon} from "@heroicons/vue/24/outline";
import {i18n} from "@/services/support/i18n";

interface Props {
  showText?: boolean,
  text?: string
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingError',
  props: {
    showText: { type: Boolean, default: false },
    text: { default: "" }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(ExclamationTriangleIcon), {
      class: "h-8 w-8 text-orange-500",
      title: _unref(i18n).loadingError
    }, null, 8, ["title"]),
    (_ctx.showText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})