import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "loading-state",
  ref: "spinner"
}

import {ref, watch} from "vue";

interface Props {
  show?: boolean,
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingSpinner',
  props: {
    show: { type: Boolean, default: false }
  },
  setup(__props: any) {

const props = __props;

const visible = ref(props.show);

watch(() => props.show, () => {
  if (props.show) {
    setTimeout(() => {
      visible.value = props.show;
    }, 500)
  } else {
    visible.value = false;
  }
});


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, _cache[0] || (_cache[0] = [
    _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 200 100\" fill=\"currentColor\" stroke=\"currentColor\" stroke-width=\"15\" data-v-46d5dec5><rect width=\"30\" height=\"30\" x=\"25\" y=\"50\" data-v-46d5dec5><animate attributeName=\"y\" calcMode=\"spline\" dur=\"2\" values=\"25;60;25;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"-.4\" data-v-46d5dec5></animate></rect><rect width=\"30\" height=\"30\" x=\"85\" y=\"50\" data-v-46d5dec5><animate attributeName=\"y\" calcMode=\"spline\" dur=\"2\" values=\"25;60;25;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"-.2\" data-v-46d5dec5></animate></rect><rect width=\"30\" height=\"30\" x=\"145\" y=\"50\" data-v-46d5dec5><animate attributeName=\"y\" calcMode=\"spline\" dur=\"2\" values=\"25;60;25;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"0\" data-v-46d5dec5></animate></rect></svg>", 1)
  ]), 512)), [
    [_vShow, visible.value]
  ])
}
}

})