<script setup lang="ts">
import {IQuiz} from "@/services/contracts/IQuiz";
import MyButton from "@/components/MyButton.vue";
import {BoltIcon, CalendarIcon, ClockIcon, DocumentIcon} from '@heroicons/vue/24/outline';
import {computed, onMounted} from "vue";
import {niceQuizStateName, QuizState} from "@/services/contracts/QuizState";
import {millisecondsToString} from "@/services/support/timeformat";
import {useAnswerListStore} from "@/stores/answerList";
import AnsweredQuestionDetail from "@/components/AnsweredQuestionDetail.vue";
import MyMathjax from "@/components/MyMathjax.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import {i18n} from "@/services/support/i18n";

const props = defineProps<{
  quiz: IQuiz
}>()

const emit = defineEmits<{
  (e: 'showQuestionButtonClick'): void
}>()

const answerListStore = useAnswerListStore();

const quizFinished = computed(() => {
  return props.quiz.state == QuizState.COMPLETED || props.quiz.state == QuizState.EXPIRED;
})

const buttonText = computed(() => {
  if (props.quiz.state == QuizState.NEW) {
    return i18n.runQuiz;
  }
  if (props.quiz.state == QuizState.IN_PROGRESS) {
    return i18n.runQuiz;
  }
  if (props.quiz.state == QuizState.COMPLETED) {
    return i18n.browseQuiz;
  }
  if (props.quiz.state == QuizState.EXPIRED) {
    return i18n.browseQuiz;
  }
  return "";
})

function showQuestionButtonClick() {
  emit('showQuestionButtonClick')
}

onMounted(() => {
  if (props.quiz.state == QuizState.COMPLETED) {
    answerListStore.fetch();
  }
})
</script>

<template>
  <div class="quiz-info">
    <div class="icon-box">
      <DocumentIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">{{ i18n.state }}</div>
        {{ niceQuizStateName(quiz.state) }}
      </div>
    </div>
    <div class="icon-box">
      <CalendarIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">{{ i18n.deadline }}</div>
        {{ quiz.deadline }}
      </div>
    </div>
    <div class="icon-box">
      <ClockIcon aria-hidden="true" class="h-7 w-7 text-orange-500"/>
      <div>
        <div class="box-headline">{{ i18n.totalTime }}</div>
        {{ millisecondsToString(quiz.spendTime) }} / {{ millisecondsToString(quiz.maxResponseTime) }}
      </div>
    </div>
    <div class="icon-box">
      <BoltIcon aria-hidden="true" class="h-7 w-7 bg-orange-500 rounded-full p-1"/>
      <div>
        <div class="box-headline">{{ i18n.achievedPoints }}</div>
        {{ quiz.achievedPoints }} / {{ quiz.maxPoints }}
      </div>
    </div>

    <div v-if="!quizFinished" class="button-line">
      <MyButton @click="showQuestionButtonClick">{{ buttonText }}</MyButton>
    </div>

    <div v-if="props.quiz.state == QuizState.COMPLETED">
      <LoadingIndicator :show-text="true" v-if="answerListStore.loadingState == LoadingState.LOADING"/>
      <div v-if="answerListStore.loadingState == LoadingState.OK">
        <MyMathjax/>
        <AnsweredQuestionDetail v-for="(answer, index) in answerListStore.answerList" :key="answer.id"
                                :answer="answer"
                                :answer-count="answerListStore.answerList.length"
                                :answer-position="index"/>
      </div>
      <div v-if="answerListStore.loadingState == LoadingState.ERROR">
        <p>{{ i18n.loadingError }}</p>
      </div>
    </div>
  </div>
</template>