import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "pt-3"
}
const _hoisted_2 = { key: 0 }

import PageLayout from "@/components/PageLayout.vue";
import {useVersionStore} from "@/stores/version";
import {computed, onMounted, watch} from "vue";
import {i18n} from "@/services/support/i18n";
import ScoreLine from "@/components/ScoreLine.vue";
import CompetitionCombo from "@/components/CompetitionCombo.vue";
import InstitutionCombo from "@/components/InstitutionCombo.vue";
import {useCompetitionListStore} from "@/stores/competitionList";
import LoadingError from "@/components/LoadingError.vue";
import {LoadingState} from "@/services/contracts/LoadingState";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import {useScoreStore} from "@/stores/score";


export default /*@__PURE__*/_defineComponent({
  __name: 'ScorePage',
  setup(__props) {

const scoreStore = useScoreStore();
const versionStore = useVersionStore();
const competitionStore = useCompetitionListStore();

onMounted(async () => {
  await Promise.all([versionStore.check(true), scoreStore.onMounted()]);
});

watch(() => scoreStore.institution, () => {
  scoreStore.fetch();
});

const isLoading = computed(() => {
  return scoreStore.loadingState == LoadingState.LOADING
      || competitionStore.loadingState == LoadingState.LOADING;
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(PageLayout, null, {
    default: _withCtx(() => [
      _createElementVNode("h1", null, _toDisplayString(_unref(i18n).scoreboard), 1),
      _cache[0] || (_cache[0] = _createElementVNode("label", { class: "block text-xs font-medium leading-6 text-gray-400" }, "v soutěži", -1)),
      _createVNode(CompetitionCombo),
      _cache[1] || (_cache[1] = _createElementVNode("label", { class: "block text-xs font-medium leading-6 text-gray-400 pt-2" }, "v rámci", -1)),
      _createVNode(InstitutionCombo),
      _createVNode(LoadingIndicator, {
        show: isLoading.value && _unref(scoreStore).list.items.length == 0,
        class: "pt-2"
      }, null, 8, ["show"]),
      (_unref(scoreStore).loadingState == _unref(LoadingState).ERROR)
        ? (_openBlock(), _createBlock(LoadingError, { key: 0 }))
        : _createCommentVNode("", true),
      (_unref(scoreStore).loadingState != _unref(LoadingState).ERROR)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            (_unref(scoreStore).list.items.length < 1 && _unref(scoreStore).loadingState == _unref(LoadingState).OK)
              ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(_unref(i18n).noScoresAvailable), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(scoreStore).list.items, (line) => {
              return (_openBlock(), _createBlock(ScoreLine, {
                key: line.idExoUser,
                data: line
              }, null, 8, ["data"]))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})