import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "loading-indicator" }
const _hoisted_2 = {
  key: 0,
  class: "text-sm"
}

import {ref, watch} from "vue";
import {LoadingState} from "@/services/contracts/LoadingState";

interface Props {
  show?: boolean,
  showText?: boolean,
  text?: string | LoadingState
}


export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingIndicator',
  props: {
    show: { type: Boolean, default: false },
    showText: { type: Boolean, default: false },
    text: { default: "" }
  },
  setup(__props: any) {

const props = __props;

const visible = ref(props.show);

watch(() => props.show, () => {
  if (props.show) {
    setTimeout(() => {
      visible.value = props.show;
    }, 200)
  } else {
    visible.value = false;
  }
});


return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createStaticVNode("<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 200 80\" data-v-f8cb7d34><rect fill=\"#F24F00\" stroke=\"#F24F00\" stroke-width=\"15\" width=\"30\" height=\"30\" x=\"25\" y=\"25\" data-v-f8cb7d34><animate attributeName=\"opacity\" calcMode=\"spline\" dur=\"2\" values=\"1;0;1;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"-.4\" data-v-f8cb7d34></animate></rect><rect fill=\"#F24F00\" stroke=\"#F24F00\" stroke-width=\"15\" width=\"30\" height=\"30\" x=\"85\" y=\"25\" data-v-f8cb7d34><animate attributeName=\"opacity\" calcMode=\"spline\" dur=\"2\" values=\"1;0;1;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"-.2\" data-v-f8cb7d34></animate></rect><rect fill=\"#F24F00\" stroke=\"#F24F00\" stroke-width=\"15\" width=\"30\" height=\"30\" x=\"145\" y=\"25\" data-v-f8cb7d34><animate attributeName=\"opacity\" calcMode=\"spline\" dur=\"2\" values=\"1;0;1;\" keySplines=\".5 0 .5 1;.5 0 .5 1\" repeatCount=\"indefinite\" begin=\"0\" data-v-f8cb7d34></animate></rect></svg>", 1)),
    (_ctx.showText)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.text), 1))
      : _createCommentVNode("", true)
  ], 512)), [
    [_vShow, visible.value]
  ])
}
}

})