import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import {useAuth0} from "@auth0/auth0-vue";
import PageLayout from "@/components/PageLayout.vue";
import {i18n} from "@/services/support/i18n";


export default /*@__PURE__*/_defineComponent({
  __name: 'CallbackPage',
  setup(__props) {

const {error} = useAuth0();

return (_ctx: any,_cache: any) => {
  return (_unref(error))
    ? (_openBlock(), _createBlock(PageLayout, { key: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("h1", null, _toDisplayString(_unref(i18n).error), 1),
          _createElementVNode("span", null, _toDisplayString(_unref(error).message), 1)
        ]),
        _: 1
      }))
    : _createCommentVNode("", true)
}
}

})