import {computed, Ref, ref} from "vue";
import {defineStore} from "pinia";
import {IExoUser} from "@/services/contracts/IExoUser";
import exoUserService from "@/services/ExoUserService";
import ExoUserService from "@/services/ExoUserService";
import {auth0} from "@/services/support/auth0";
import {LoadingState} from "@/services/contracts/LoadingState";
import {logError} from "@/services/support/error";

export const EMPTY_EXOUSER: IExoUser = {
    id: 0,
    ssoId: "",
    email: "",
    firstName: "",
    lastName: "",
    preferredEmail: "",
    ssoUsername: "",
    nickname: "",
    profileImageData: undefined,

    participationConsent: false,
    termsAndPrivacyConsent: false,
    contactConsent: false,
    photoConsent: false,
};

export const useExoUserStore
    = defineStore('exoUser', () => {

    const changed = ref(false);
    const exoUser: Ref<IExoUser> = ref(EMPTY_EXOUSER);
    const loadingState = ref(LoadingState.LOADING);

    async function save(eu: IExoUser) {
        console.debug('Saving user...');

        const promise = ExoUserService.patch(eu.ssoId, eu);

        try {
            const response = await promise;
            exoUser.value = response.data;
            return response;
        } catch (err) {
            logError(`Failed to save user - id: ${eu.id}`, err);
            return Promise.reject(err);
        }
    }

    async function fetch(silent: boolean = false) {

        if (!silent) {
            loadingState.value = LoadingState.LOADING;
        }

        console.debug('Fetching user...');

        const ssoId = auth0.user.value?.sub as string;

        if (ssoId == undefined) {
            console.debug('No SSOID');
            exoUser.value = EMPTY_EXOUSER;
            loadingState.value = LoadingState.ERROR;
            return;
        }

        try {
            const response = await exoUserService.get(ssoId);
            exoUser.value = response.data;
            loadingState.value = LoadingState.OK;
        } catch (err) {
            exoUser.value = EMPTY_EXOUSER;
            loadingState.value = LoadingState.ERROR;
            logError('Failed to fetch user ' + ssoId, err);
        }
    }

    function silentFetch() {
        return fetch(true);
    }

    const fullName = computed(() => {
        return exoUser.value.firstName + ' ' + exoUser.value.lastName;
    });

    const isProfileCompleted = computed(() => {
        if (exoUser.value.id == EMPTY_EXOUSER.id) {
            return true;
        }
        if (!exoUser.value.participationConsent) {
            return false;
        }
        if (!exoUser.value.termsAndPrivacyConsent) {
            return false;
        }
        if (exoUser.value.nickname == null) {
            return false;
        }
        return exoUser.value.nickname.trim() != "" && isEmailVerified.value;
    });

    const isEmailVerified = computed(() => auth0.user.value?.email_verified ? true : false);

    (async () => {
        await fetch();
    })();

    return {exoUser, fullName, silentFetch, isProfileCompleted, isEmailVerified, loadingState, save, changed}
})