<script setup lang="ts">

import {useExoUserStore} from "@/stores/exouser";
import {BoltIcon} from "@heroicons/vue/24/outline";
import {IScore} from "@/services/contracts/IScore";

const exoUserStore = useExoUserStore();

defineProps<{ data: IScore }>();
</script>

<template>
  <div>
    <div v-if="data.padding" class="text-center text-cez-color">
      ...
    </div>

    <div class="score-line" v-else>
      <div class="w-14 text-right">
        {{ data.rank }}.
      </div>

      <div class="flex bg-gray-900 rounded-full items-center justify-between w-full flex-shrink overflow-hidden"
           :class="data.idExoUser == exoUserStore.exoUser.id ? 'selected' : ''">

        <div class="flex items-center flex-shrink overflow-hidden">
          <img class="block h-12 w-12 rounded-full border-orange-500 border-2 flex-shrink-0 flex-grow-0"
               :src="data.imageData == null ? '/img/logo.png' : data.imageData"
               alt=""/>

          <div class="pl-3 pr-6 flex-shrink overflow-hidden">
            <p class="nickname">{{ data.nickname }}</p>
            <p class="teams">{{ data.teams }}</p>
          </div>
        </div>
      </div>

      <div class="icon-box flex-shrink-0">
        <BoltIcon class="h-6 w-6 bg-orange-500 rounded-full p-1"/>
        <span class="whitespace-nowrap w-14">{{ data.points }}</span>
      </div>

    </div>

  </div>

</template>

<style scoped>

.score-line {
  @apply flex items-center pt-1 pb-1 gap-3;
}

.score-line .selected {
  @apply bg-gradient-to-br from-emerald-400 to-emerald-800;
}

.score-line .nickname {
  @apply text-ellipsis whitespace-nowrap overflow-hidden;
}

.score-line .teams {
  @apply text-xs text-gray-500 text-ellipsis whitespace-nowrap overflow-hidden;;
}

.score-line .selected .teams {
  @apply text-white
}

.score-line img {
  @apply bg-gray-900;
}
</style>