import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

import {useAuth0} from "@auth0/auth0-vue";
import MySuperApp from "@/components/MySuperApp.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const {isLoading, isAuthenticated} = useAuth0();

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_router_view),
    (!_unref(isLoading) && _unref(isAuthenticated))
      ? (_openBlock(), _createBlock(MySuperApp, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})